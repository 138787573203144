import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Filter, FilterInterface } from '../../lib/model/Filter';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  /**
   * Filters to display.
   */
  @Input() filters: Filter[];
  /**
   * Whether Export CSV button is enabled.
   */
  @Input() exportButton?: boolean = false;
  /**
   * Triggers when a filter is updated.
   */
  @Output() public filterUpdated: EventEmitter<Filter> = new EventEmitter();
  @Output() public exportCsv: EventEmitter<any> = new EventEmitter();
  /**
   * Number of used filters.
   */
  public filtersUsed: number = 0;
  private filterSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.countActiveFilters();

    const self = this;
    this.filterSubscription = this.filterUpdated.subscribe(() => {
      self.countActiveFilters();
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  countActiveFilters() {
    this.filtersUsed = 0;
    this.filters.forEach((filter) => {
      // @ts-ignore
      if (typeof(filter.selected) !== 'undefined') {
        // @ts-ignore
        this.filtersUsed += filter.selected.length;
      }
      // @ts-ignore
      if (typeof(filter.value) !== 'undefined') {
        this.filtersUsed++;
      }
    });
  }

  public reset() {
    this.filters.forEach((filter) => {
      // @ts-ignore
      if (typeof(filter.selected) !== 'undefined') {
        // @ts-ignore
        filter.selected = [];
      }
      // @ts-ignore
      if (typeof(filter.value) !== 'undefined') {
        // @ts-ignore
        filter.value = undefined;
      }

      this.filterUpdated.emit(filter);
    });
  }
}
