import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format price with superior €cents by inserting HTML.
 */
@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {

  transform(value: any): string {
    const currencyPipe = new CurrencyPipe('fr-FR');
    const price: string = currencyPipe.transform(value, 'EUR');
    return price.replace(/([\d\s]+),(\d{2}).*/gi, '$1<sup>€$2</sup>');
  }
}
