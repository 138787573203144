import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Category } from '../../lib/model/Category';
import { CategoryService } from '../../lib/services/category.service';
import { ProductService } from '../../lib/services/product.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public category: Category;
  public brands: any[] = [];

  constructor(private router: Router,
              public sharedVariablesService: SharedVariablesService,
              private categoryService: CategoryService,
              private productService: ProductService) {
  }

  ngOnInit() {
    const self = this;

    // Fetch categories and retrieve current (linked with sale channel)
    if (this.sharedVariablesService.transaction.properties.categoryCode) {
      this.categoryService.getCategoriesTree(this.sharedVariablesService.transaction.properties.categoryCode).pipe(take(1)).subscribe((category) => {
        if (category) {
          self.category = category;
        }
      });
    }

    // Fetch brands from Elastic from current category
    this.productService.searchProducts( this.sharedVariablesService.transaction.properties.categoryCode,
                                        self.sharedVariablesService.saleChannel.code,
                                        0,
                                        0,
                                        [],
                                        [],
                                        [],
                                        [],
                                        [],
                                        [],
                                        undefined,
                                        this.sharedVariablesService.transaction.properties.rangeRank).toPromise().then((elasticSearchResult) => {
      if (elasticSearchResult.filters.brand) {
        elasticSearchResult.filters.brand.buckets.forEach((bucket) => {
          self.brands.push({key: bucket.key, value: bucket.value});
        });
      }
    });
  }
}
