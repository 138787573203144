<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="product?.name"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2><ng-container *ngIf="sharedVariablesService.transaction?.properties.categoryName">{{ sharedVariablesService.transaction?.properties.categoryName }} - </ng-container>{{ sharedVariablesService.transaction?.properties.rangeName }}
        <ng-container *ngIf="sharedVariablesService.saleChannel.type === 'permanent'">- {{ sharedVariablesService.transaction?.customer.priceScaleCode }}</ng-container></h2>
    </div>
    <div class="flex">
      <h2><span class="lighter">Du</span> {{ sharedVariablesService.saleChannel.openingDate | date : 'shortDate' }}
        <span class="lighter">au</span> {{ sharedVariablesService.saleChannel.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le classeur" class="link" [routerLink]="['/tableau-de-bord', sharedVariablesService.saleChannel.code]"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<app-menu></app-menu>
<div class="section mt20">
  <app-product-item *ngIf="product" [withDetails]="true" [product]="product"></app-product-item>
</div>
