import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Product, ProductAdapter } from './Product';
import { ProductDeclinationAdapter } from './ProductDeclination';

export interface ElasticSortingField {
  field: string;
  order: string;
}

export interface ElasticBucket {
  key: string;
  value: string;
}

export interface ElasticFilter {
  type: string;
  label: string;
  buckets: ElasticBucket[];
}

export interface ElasticFilters {
  [prop: string]: ElasticFilter;
}

export class ElasticSearchResult {
  products: Product[];
  filters: ElasticFilters = {};
  sorting: ElasticSortingField[] = [];
  total: number;

  constructor(products: Product[], filters: ElasticFilters, sorting: ElasticSortingField[], total: number) {
    this.products = products;
    this.filters = filters;
    this.sorting = sorting;
    this.total = total;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ElasticSearchResultAdapter implements Adapter<ElasticSearchResult> {
  adapt(item: any): ElasticSearchResult {
    const productAdapter = new ProductAdapter();
    return new ElasticSearchResult(
      item.products ? item.products.map(elt => productAdapter.adapt(elt)) : [],
      item.filters ? item.filters : [],
      item.sorting ? item.sorting : [],
      item.total
    );
  }
}
