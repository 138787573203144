<app-header></app-header>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="fill-flex-space"></div>
  <h1>Bienvenue, veuillez choisir un salon.</h1>
  <div class="fill-flex-space"></div>
</mat-toolbar>
<div class="section salons p20">
  <h2 *ngIf="saleChannels">{{ saleChannels?.length }} salon{{ saleChannels?.length > 1 ? 's' : '' }}</h2>
  <div class="row ">
    <div class="col-md-4 col-sm-2 text-center" *ngFor="let saleChannel of saleChannels">
      <div class="salon" matRipple (click)="openExhibition(saleChannel)" [ngClass]="{closed: saleChannel.exhibitionOpeningTime() < 0, soon:saleChannel.exhibitionOpeningTime() > 0}">
        <div class="image"><img [class.visible]="saleChannelsImages[saleChannel.code]" [src]="saleChannelsImages[saleChannel.code] ? saleChannelsImages[saleChannel.code] : ''" alt="Salon" /></div>
        <h3>{{ saleChannel.name }}</h3>
        <div class="until mb20" *ngIf="saleChannel.isOpen()">Jusqu'au {{ saleChannel.closingDate | date : 'shortDate' }}</div>
        <div class="until mb20" *ngIf="!saleChannel.isOpen()">Du {{ saleChannel.openingDate | date : 'shortDate' }} au {{ saleChannel.closingDate | date : 'shortDate' }}</div>
        <span class="type">Salon {{ saleChannel.getHumanReadableType() }}</span>
      </div>
    </div>
  </div>
  <ngx-ui-loader [loaderId]="'loader-exhibitions'"></ngx-ui-loader>
</div>
