<h1 mat-dialog-title>Contact</h1>
<div mat-dialog-content>
  <p>Coordonnées pour le salon <strong>{{ data.saleChannel.name }}</strong> :</p>
  <div class="row mb10">
    <div class="col-md-4 text-right"><mat-icon class="font-size" aria-hidden="false" aria-label="Téléphone">local_phone</mat-icon></div>
    <div class="col-md-8 no-gutters"><strong>{{ data.saleChannel.phone }}</strong></div>
  </div>
  <div class="row mb20">
    <div class="col-md-4 text-right pt5"><span><mat-icon class="font-size" aria-hidden="false" aria-label="Email">email</mat-icon></span></div>
    <div class="col-md-8 no-gutters"><a mat-stroked-button class="small" color="primary" href="mailto:{{ data.saleChannel.email }}">{{ data.saleChannel.email }}</a></div>
  </div>
</div>
<div mat-dialog-actions class="mt5 mb5 text-center">
  <button type="submit" mat-raised-button color="accent" [mat-dialog-close]="true">OK</button>
</div>
