import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { Advertisement } from '../../lib/model/Advertisement';
import { SaleChannel } from '../../lib/model/SaleChannel';
import { User } from '../../lib/model/User';
import { AdvertisementService } from '../../lib/services/advertisement.service';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { ContactComponent } from '../contact/contact.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public searchForm: UntypedFormGroup;
  public user: User;
  public sizeGuide: Advertisement;
  public helpFile: Advertisement;
  private saleChannelSubscription: Subscription;

  constructor(public dialog: MatDialog,
              private router: Router,
              public sharedVariablesService: SharedVariablesService,
              private authenticationService: AuthenticationService,
              private advertisementService: AdvertisementService,
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.saleChannelSubscription = this.sharedVariablesService.saleChannelObservable().subscribe((saleChannel) => {
      // Fetch guide des tailles et aide
      if (saleChannel) {
        this.advertisementService.getAdvertisements(saleChannel.code, 'SIZE_GUIDE').toPromise().then((advertisement) => {
          this.sizeGuide = advertisement.length > 0 ? advertisement[0] : undefined;
        });
        this.advertisementService.getAdvertisements(saleChannel.code, 'HELP_FILE').toPromise().then((advertisement) => {
          this.helpFile = advertisement.length > 0 ? advertisement[0] : undefined;
        });
      } else {
        this.sizeGuide = undefined;
        this.helpFile = undefined;
      }
    });
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();

    // Initialize search form
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(undefined),
    });
  }

  ngOnDestroy(): void {
    this.saleChannelSubscription.unsubscribe();
  }

  openResetPasswordDialog(): void {
    this.dialog.open(ResetPasswordComponent, { width: '400px', data: { email: this.user.email }});
  }

  openContactDialog(): void {
    this.googleAnalyticsService.eventEmitter('click_contact');
    this.dialog.open(ContactComponent, { width: '400px', data: { saleChannel: this.sharedVariablesService.saleChannel }});
  }

  logout() {
    this.googleAnalyticsService.eventEmitter('logout');
    this.authenticationService.logout();
  }

  search() {
    const term = this.searchForm.get('search').value;
    this.googleAnalyticsService.searchEvent(term);
    this.router.navigate(['classeur', this.sharedVariablesService.transaction.id, 'recherche'], { queryParams: { term }});
    this.searchForm.get('search').setValue(undefined);
  }

  isKeyneosoftHosted(redirectionUrl: string) {
    return (redirectionUrl && redirectionUrl.includes('keyneosoft'));
  }

  buildRedirectionUrl(redirectionUrl: string): string {
    const excludedTypes = ['.mp4', '.MP4'];
    // For now, mp4 cause 404 if token is present in URL, so don't had it. SYS-327
    if (this.isKeyneosoftHosted(redirectionUrl) && !excludedTypes.some(v => redirectionUrl.includes(v))) {
      return redirectionUrl + '&token=' + this.authenticationService.getCurrentUser().token;
    } else {
      return redirectionUrl;
    }
  }
}
