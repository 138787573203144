import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Filter } from '../model/Filter';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filterSubject: Subject<Filter> = new Subject<Filter>();

  constructor() {
  }
}
