import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ItemAdapter } from './Item';
import { Price, PriceAdapter } from './Price';
import { ProductVariant, ProductVariantAdapter } from './ProductVariant';

export interface ProductDeclinationImage {
  id: string;
  name: string;
  url: string;
  rank: string;
}

export interface ProductDeclinationProperties {
  color_image_url: string[];
  size_curves: string[];
}

export class ProductDeclination {
  id: string;
  reference: string;
  productId: string;
  name: string;
  type: string;
  value: string;
  creationDate: Date;
  modificationDate: Date;
  salesUnitCount: number;
  totalSalesUnitCount: number;
  totalAmount: number;
  totalPurchasingAmount: number;
  marginRate: number;
  price: Price;
  imageUrl: string;
  productVariants: ProductVariant[] = [];
  images: ProductDeclinationImage[] = [];
  properties: ProductDeclinationProperties;
  relatedDeclinations: ProductDeclination[] = [];

  constructor(id: string,
              reference: string,
              productId: string,
              name: string,
              type: string,
              value: string,
              creationDate: Date,
              modificationDate: Date,
              salesUnitCount: number,
              totalSalesUnitCount: number,
              totalAmount: number,
              totalPurchasingAmount: number,
              marginRate: number,
              price: Price,
              imageUrl: string,
              productVariants: ProductVariant[],
              images: ProductDeclinationImage[],
              properties: ProductDeclinationProperties,
              relatedDeclinations: ProductDeclination[]) {
    this.id = id;
    this.reference = reference;
    this.productId = productId;
    this.name = name;
    this.type = type;
    this.value = value;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.salesUnitCount = salesUnitCount;
    this.totalSalesUnitCount = totalSalesUnitCount;
    this.totalAmount = totalAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.marginRate = marginRate;
    this.price = price;
    this.imageUrl = imageUrl;
    this.productVariants = productVariants;
    this.images = images;
    this.properties = properties;
    this.relatedDeclinations = relatedDeclinations;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductDeclinationAdapter implements Adapter<ProductDeclination> {
  adapt(item: any): ProductDeclination {
    const productVariantAdapter = new ProductVariantAdapter();
    const priceAdapter = new PriceAdapter();

    if (item) {
      return new ProductDeclination(
        item.id,
        item.reference,
        item.productId,
        item.name,
        item.type,
        item.value,
        item.creationDate ? new Date(item.creationDate) : undefined,
        item.modificationDate ? new Date(item.modificationDate) : undefined,
        item.salesUnitCount,
        item.totalSalesUnitCount,
        item.totalAmount,
        item.totalPurchasingAmount,
        item.marginRate,
        item.price ? priceAdapter.adapt(item.price) : undefined,
        item.imageUrl,
        item.productVariants ? item.productVariants.map(elt => productVariantAdapter.adapt(elt)) : [],
        item.images,
        item.properties,
        item.relatedDeclinations ? item.relatedDeclinations.map(elt => this.adapt(elt)) : []
      );
    } else {
      return undefined;
    }
  }
}
