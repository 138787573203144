import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { ProductService } from '../../lib/services/product.service';
import { PlanogramElement } from '../../lib/model/Planogram';

@Component({
  selector: 'app-planogram-print',
  templateUrl: './planogram-print.component.html',
  styleUrls: ['./planogram-print.component.scss'],
})
export class PlanogramPrintComponent implements OnInit {
  public readonly NATURAL_WIDTH: number = 133;
  public readonly NATURAL_HEIGHT: number = 266;
  public readonly DEFAULT_RATIO: number = 3.067669173; // 816px / 266cm
  public ELEMENT_WIDTH: number = 408; // 408px de large = 133cm x ratio
  public ELEMENT_HEIGHT: number = 816; // 816px de haut = (220 cm + 46cm de marge) x ratio
  public RATIO: number = this.DEFAULT_RATIO; // 816px / 266cm
  public nbElements: number = 1;
  public planogramData: PlanogramElement[];

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private productService: ProductService) {

  }

  ngOnInit() {
    this.setRatio(3.5);
    this.sharedVariablesService.loadFullTransaction(this.route.snapshot.paramMap.get('transactionid')).then((transaction) => {
      // We compute the number of elements
      const match = this.sharedVariablesService.transaction.properties.rangeName.match(/(\d+,?\d?).*/i);
      if (match.length > 1) {
        this.nbElements = Math.ceil(Number(match[1]));
      }
      // Call WS for planogram data
      this.productService.getPlanogram(transaction.properties.categoryCode, transaction.properties.rangeCode).toPromise().then((planogram) => {
        this.planogramData = planogram.elements;
        this.print();
      });
    });
  }

  private setRatio(ratio: number) {
    this.RATIO = ratio;
    this.ELEMENT_WIDTH = this.NATURAL_WIDTH * this.RATIO;
    this.ELEMENT_HEIGHT = this.NATURAL_HEIGHT * this.RATIO;
  }

  print() {
    setTimeout(() => {
      window.print();
      window.close();
    }, 1500);
  }
}
