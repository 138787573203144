import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public error: Subject<string>;

  constructor() {
    this.error = new Subject<string>();
  }

  notify(error: string) {
    this.error.next(error);
  }
}
