export enum FilterPeriod {
  TODAY = 'TODAY',
  CURRENT_MONTH = 'CURRENT_MONTH',
  MONTH = 'MONTH',
  CURRENT_YEAR = 'CURRENT_YEAR',
  YEAR = 'YEAR',
}

export type Filter = FilterSingleChoice | FilterSingleSelect | FilterSingleChoiceTree | FilterMultipleChoiceTree | FilterMultipleChoice | FilterSearch | FilterDateInterval | FilterDateIntervalByPeriod;

export interface FilterInterface {
  /**
   * Unique ID for the filter.
   */
  id: string;
  /**
   * Title of the filter.
   */
  name: string;
  /**
   * Whether the filter is visible or not.
   */
  visible?: boolean;

  /**
   * Values possibles for the filter (in case of multiple choices).
   */
  options?: FilterOption[];
  /**
   * List of ids of option(s) selected if the filter offer a choice.
   */
  selected?: string[];
  /**
   * Value of the filter (text, id).
   */
  value?: string|FilterPeriod;
  /**
   * Starting date.
   */
  startingDate?: Date;
  /**
   * Ending date.
   */
  endingDate?: Date;
  /**
   * Possible periods for date intervals pre-selection.
   */
  periods?: FilterPeriod[];
  /**
   * Whether the filter can be reset by user.
   */
  canReset?: boolean;
  /**
   * Method for computing dates from filter period.
   * @param filterPeriod
   */
  computeDates?: (filterPeriod?: FilterPeriod) => void;
  /**
   * Returns the Filter class name of an instance.
   */
  getType(): string;
}

export class FilterSearch implements FilterInterface {
  readonly id: string;
  name: string;
  value: string | FilterPeriod;

  /**
   * Filter with search field.
   * @param id
   * @param name
   * @param value
   */
  constructor(id: string, name: string, value: string) {
    this.id = id;
    this.name = name;
    this.value = value;
  }

  getType(): string {
    return 'FilterSearch';
  }
}

export class FilterSingleChoice implements FilterInterface {
  readonly id: string;
  name: string;
  options: FilterOption[];
  value: string | FilterPeriod;
  visible: boolean = false;

  /**
   * Filter with a list of choices (one can be selected at a time).
   * @param id
   * @param name
   * @param options
   * @param value
   * @param visible
   */
  constructor(id: string, name: string, options: FilterOption[], value: string, visible: boolean = false) {
    this.id = id;
    this.name = name;
    this.options = options;
    this.value = value;
    this.visible = visible;
  }

  getType(): string {
    return 'FilterSingleChoice';
  }
}

export class FilterSingleSelect extends FilterSingleChoice {
  /**
   * Filter with a list of choices (one can be selected at a time).
   * @param id
   * @param name
   * @param options
   * @param value
   * @param visible
   */
  constructor(id: string, name: string, options: FilterOption[], value: string, visible: boolean = false) {
    super(id, name, options, value, visible);
  }

  getType(): string {
    return 'FilterSingleSelect';
  }
}

export class FilterSingleChoiceTree extends FilterSingleChoice {
  /**
   * Filter with a list of choices displayed as a tree (for recursive options).
   * Only one can be selected at a time.
   * @param id
   * @param name
   * @param options
   * @param value
   * @param visible
   */
  constructor(id: string, name: string, options: FilterOption[], value: string, visible: boolean = false) {
    super(id, name, options, value, visible);
  }

  get selected() {
    return [this.value];
  }

  getType(): string {
    return 'FilterSingleChoiceTree';
  }
}

export class FilterMultipleChoice implements FilterInterface {
  id: string;
  name: string;
  options: FilterOption[];
  selected: string[];
  visible: boolean = false;
  hint: string = undefined;

  /**
   * Filter with a list of choices (many can be selected at a time).
   * @param id
   * @param name
   * @param options
   * @param selected
   * @param visible
   * @param hint
   */
  constructor(id: string, name: string, options: FilterOption[], selected: string[], visible: boolean = false, hint?: string) {
    this.id = id;
    this.name = name;
    this.options = options;
    this.selected = selected;
    this.visible = visible;
    this.hint = hint;
  }

  getType(): string {
    return 'FilterMultipleChoice';
  }
}

export class FilterMultipleChoiceTree extends FilterMultipleChoice {
  /**
   * Filter with a list of choices (many can be selected at a time).
   * @param id
   * @param name
   * @param options
   * @param selected
   * @param visible
   * @param hint
   */
  constructor(id: string, name: string, options: FilterOption[], selected: string[], visible: boolean = false, hint?: string) {
    super(id, name, options, selected, visible, hint);
  }
  getType(): string {
    return 'FilterMultipleChoiceTree';
  }
}

export class FilterMultipleChoiceTags extends FilterMultipleChoice {
  /**
   * Filter with a list of choices (many can be selected at a time).
   * @param id
   * @param name
   * @param options
   * @param selected
   * @param visible
   * @param hint
   */
  constructor(id: string, name: string, options: FilterOption[], selected: string[], visible: boolean = false, hint?: string) {
    super(id, name, options, selected, visible, hint);
  }
  getType(): string {
    return 'FilterMultipleChoiceTags';
  }
}

export class FilterDateInterval implements FilterInterface {
  id: string;
  name: string;
  visible: boolean;
  startingDate?: Date;
  endingDate?: Date;
  canReset: boolean = false;

  /**
   * Filter with date interval (user select starting and ending date).
   * @param id Unique identifier of the filter.
   * @param name Label (translation key) of the filter.
   * @param visible Whether the filter is visible or not.
   * @param startingDate Starting date of the interval (if initialized).
   * @param endingDate Ending date of the interval (if initialized).
   * @param canReset Whether the filter can be reset by the user.
   */
  constructor(id: string, name: string, visible: boolean = false, startingDate?: Date, endingDate?: Date, canReset: boolean = false) {
    this.id = id;
    this.name = name;
    this.visible = visible;
    this.canReset = canReset;
    if (startingDate === undefined && endingDate === undefined) {
      this.startingDate = startingDate;
      this.endingDate = endingDate;
    } else {
      this.startingDate = new Date();
      this.startingDate.setHours(0, 0, 0, 0);
      this.endingDate = new Date();
      this.endingDate.setHours(23, 59, 59, 999);
    }
  }

  getType(): string {
    return 'FilterDateInterval';
  }
}

export class FilterDateIntervalMultipleChoice extends FilterDateInterval {
  options: FilterOption[];
  value: string;

  /**
   * Filter with date interval (user select starting and ending date) and a choice (select) of target property to apply filter on.
   * @param id Unique identifier of the filter.
   * @param name Not used for this kind of filter.
   * @param visible Whether the filter is visible or not.
   * @param startingDate Starting date of the interval (if initialized).
   * @param endingDate Ending date of the interval (if initialized).
   * @param canReset Whether the filter can be reset by the user.
   * @param options Options (choices) to apply the date interval filter on.
   * @param value Default value (id of one of FilterOption), auto defaults to first option.
   */
  constructor(id: string, name: string, visible: boolean, startingDate: Date, endingDate: Date, canReset: boolean, options: FilterOption[], value: string) {
    super(id, name, visible, startingDate, endingDate, canReset);
    this.options = options;
    this.value = value;

    if (this.value === undefined && this.options.length > 0) {
      this.value = this.options[0].id;
    }
  }

  getType(): string {
    return 'FilterDateIntervalMultipleChoice';
  }
}

export class FilterDateIntervalByPeriod implements FilterInterface {
  id: string;
  name: string;
  visible: boolean;
  startingDate?: Date;
  endingDate?: Date;
  periods: FilterPeriod[];
  value: FilterPeriod;

  /**
   * Filter with date period choice, among periods provided.
   * @param id
   * @param name
   * @param visible
   * @param periods
   * @param value
   */
  constructor(id: string, name: string, visible: boolean, periods: FilterPeriod[], value: FilterPeriod = FilterPeriod.TODAY) {
    this.id = id;
    this.name = name;
    this.visible = visible;
    this.periods = periods;
    this.computeDates(value);
  }

  getType(): string {
    return 'FilterDateIntervalByPeriod';
  }

  computeDates(filterPeriod?: FilterPeriod) {
    this.value = filterPeriod;
    switch (filterPeriod) {
      case 'TODAY': {
        this.startingDate = new Date(new Date().setHours(0, 0, 0, 999));
        this.endingDate = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      }
      case 'CURRENT_MONTH': {
        this.startingDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        this.endingDate = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      }
      case 'MONTH': {
        this.startingDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        this.endingDate = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      }
      case 'CURRENT_YEAR': {
        this.startingDate = new Date(new Date().getFullYear(), 0, 1);
        this.endingDate = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      }
      case 'YEAR': {
        this.startingDate = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
        this.endingDate = new Date(new Date().setHours(23, 59, 59, 999));
        break;
      }
      default: {
        this.endingDate = undefined;
        this.startingDate = undefined;
      }
    }
  }
}

/**
 * Modelize an option for a filter.
 */
export class FilterOption {
  /**
   * Identifier of the option.
   */
  id: string;
  /**
   * Name of the option.
   */
  name: string;
  /**
   * Child options.
   */
  children?: FilterOption[];

  constructor(id: string, name: string, children?: FilterOption[]) {
    this.id = id;
    this.name = name;
    if (children) {
      this.children = children;
    }
  }
}
