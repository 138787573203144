import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SaleChannel, SaleChannelAdapter } from '../model/SaleChannel';
import { Transaction, TransactionAdapter } from '../model/Transaction';
import { TransactionService } from './transaction.service';

@Injectable({
  providedIn: 'root',
})
export class SharedVariablesService {
  private _transaction: Transaction;
  private _saleChannel: SaleChannel;
  private _env: string = 'PROD';
  public saleChannelSubject: Subject<SaleChannel>;

  constructor(private transactionAdapter: TransactionAdapter,
              private saleChannelAdapter: SaleChannelAdapter,
              private transactionService: TransactionService,
              private ngxLoaderService: NgxUiLoaderService) {
    // Load from local storage
    if (window.localStorage.getItem('saleChannel') !== null) {
      this._saleChannel = this.saleChannelAdapter.adapt(JSON.parse(window.localStorage.getItem('saleChannel')));
    }
    if (window.localStorage.getItem('transaction') !== null) {
      this._transaction = this.transactionAdapter.adapt(JSON.parse(window.localStorage.getItem('transaction')));
    }

    this.saleChannelSubject = new BehaviorSubject<SaleChannel>(undefined);
  }

  clear() {
    this.transaction = undefined;
    this.saleChannel = undefined;
    window.localStorage.removeItem('transaction');
    window.localStorage.removeItem('saleChannel');
  }

  get transaction(): Transaction {
    return this._transaction;
  }

  set transaction(value: Transaction) {
    this._transaction = value;
    if (value !== undefined) {
      window.localStorage.setItem('transaction', JSON.stringify(this.transactionAdapter.prepare(this._transaction)));
    } else {
      window.localStorage.removeItem('transaction');
    }
  }

  get saleChannel(): SaleChannel {
    return this._saleChannel;
  }

  set saleChannel(value: SaleChannel) {
    this._saleChannel = value;
    this.saleChannelSubject.next(value);

    if (value !== undefined) {
      window.localStorage.setItem('saleChannel', JSON.stringify(this._saleChannel));
    } else {
      window.localStorage.removeItem('saleChannel');
    }
  }

  public saleChannelObservable(): Observable<SaleChannel> {
    return this.saleChannelSubject.asObservable();
  }

  public loadFullTransaction(transactionId?: string): Promise<Transaction> {
    return new Promise((resolve, reject) => {
      this.ngxLoaderService.start('loadfulltransaction');
      this.transactionService.getTransactionSystemeU(transactionId ? transactionId : this._transaction.id).pipe(take(1)).subscribe((systemeUTransaction) => {
        this._transaction = systemeUTransaction.transaction;
        this.ngxLoaderService.stop('loadfulltransaction');
        resolve(systemeUTransaction.transaction);
      }, (err) => {
        this.ngxLoaderService.stop('loadfulltransaction');
        reject();
      });
    });
  }

  get env(): string {
    return this._env;
  }

  set env(value: string) {
    this._env = value;
  }

  getEnv() {
    let currentURI: any = window.location.host;
    currentURI = currentURI.split('.');
    if (currentURI.includes('staging') || currentURI.includes('testing') || currentURI.includes('localhost:4200')) {
      this._env = undefined;
    } else {
      this._env = 'PROD';
    }
  }
}
