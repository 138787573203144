import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterOption, FilterPeriod } from '../../../../lib/model/Filter';

@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
})
export class OptionListComponent implements OnInit {
  /**
   * The filter to initialize the component with.
   */
  @Input() options: FilterOption[];
  /**
   * The parent option of current options.
   */
  @Input() parentOption: FilterOption;
  /**
   * Indentation level (level of the option in the tree depth).
   */
  @Input() level: number = 0;
  /**
   * Term filter to hide not matching options.
   */
  @Input() filterTerm: string;
  /**
   * Selected option of this component.
   */
  @Input() selectedOptions: string[] = [];
  /**
   * Whether multi selection is possible.
   */
  @Input() multi: boolean;
  /**
   * Emit the option selected as an event.
   */
  @Output() optionsSelected: EventEmitter<string[]> = new EventEmitter();
  /**
   * Emit when parent option must be unchecked.
   */
  @Output() uncheckParent: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  updateSelectedOptions(options: string[]) {
    this.selectedOptions = options;
    this.optionsSelected.emit(this.selectedOptions);
  }

  checkOption(option: FilterOption) {
    this.addRemoveSelectionOption(option);

    if (this.multi && this.isSelected(option) && option.children) {
      option.children.forEach((child) => {
        this.addRemoveSelectionOption(child, true);
      });
    } else if (this.multi && !this.isSelected(option) && option.children) {
      option.children.forEach((child) => {
        this.addRemoveSelectionOption(child, false, true);
      });
    }

    this.optionsSelected.emit(this.selectedOptions);
  }

  isSelected(option: FilterOption) {
    return (this.selectedOptions.indexOf(option.id) > -1);
  }

  addRemoveSelectionOption(option: FilterOption, keepIfExists: boolean = false, removeOnly: boolean = false) {
    const existing = this.selectedOptions.indexOf(option.id);
    // If option is unchecked
    if (existing > -1 && !keepIfExists) {
      this.selectedOptions.splice(existing, 1);
      // Uncheck parent
      if (this.parentOption) {
        this.addRemoveSelectionOption(this.parentOption, false, true);
        this.uncheckParent.emit(true);
      }
    } else if (existing < 0 && !removeOnly) { // Option is checked
      if (!this.multi) {
        this.selectedOptions = [option.id];
      } else {
        this.selectedOptions.push(option.id);
      }
    }
  }

  uncheckMyParent() {
    if (this.parentOption) {
      this.addRemoveSelectionOption(this.parentOption, false, true);
    }
  }
}
