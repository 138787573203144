import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SharedVariablesService } from '../services/shared-variables.service';

/**
 * Guard to check whether we have a sale channel in memory (needed for displaying some pages).
 */
@Injectable({ providedIn: 'root' })
export class DataGuard implements CanActivate {

  constructor(private router: Router,
              private sharedVariablesService: SharedVariablesService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.sharedVariablesService.saleChannel) {
      // We have a sale channel so return true
      return true;
    }

    // No sale channel, go to exhibitions to select one
    this.router.navigate(['/salons']);
    return false;
  }
}
