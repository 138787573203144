import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaleChannel, SaleChannelAdapter } from '../model/SaleChannel';

@Injectable({
  providedIn: 'root',
})
export class SaleChannelService {

  constructor(private http: HttpClient, private adapter: SaleChannelAdapter) {
  }

  getRunningExhibitions(isAdmin: boolean = false): Observable<SaleChannel[]> {
    let params = new HttpParams();

    params = params.set('active', 'true')
                   .set('class', 'Exhibition');


    // If not admin, user can see only opened exhibition
    if (!isAdmin) {
      params = params.set('openedAt', (new Date()).toISOString());
      // We look for exhibitions closed up to 30 days ago
      params = params.set('closedAt', new Date(new Date().setDate(new Date().getDate() - 30)).toISOString());
    }

    return this.http.get<SaleChannel[]>('/api/systemeu/saleChannels', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getExhibition(saleChannelCode: string): Observable<SaleChannel> {
    return this.http.get<SaleChannel[]>('/api/saleChannels/' + saleChannelCode)
      .pipe(map(item => this.adapter.adapt(item)));
  }
}
