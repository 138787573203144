import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ItemAdapter } from './Item';
import { Organisation, OrganisationAdapter } from './Organisation';
import { User, UserAdapter } from './User';

export class Customer extends User {
  priceScaleCode: string;
  customerGroups: string[];

  constructor(email: string, token: string, firstName: string, lastName: string, groups: string[], organisations: any[], organisation: Organisation, locale: string, priceScaleCode: string, customerGroups: string[]) {
    super(email, token, firstName, lastName, groups, organisations, organisation, locale);
    this.priceScaleCode = priceScaleCode;
    this.customerGroups = customerGroups;
  }

  static createFromUserOrCustomer(user: User | Customer, priceScaleCode: string): Customer {
    const tmpCustomer = { priceScaleCodes: priceScaleCode };
    Object.assign(tmpCustomer, user);
    const customerAdapter = new CustomerAdapter();
    return customerAdapter.adapt(tmpCustomer);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerAdapter implements Adapter<Customer> {
  adapt(item: any): Customer {
    const organisationAdapter = new OrganisationAdapter();

    return new Customer(item.email,
      item.token,
      item.firstName,
      item.lastName,
      item.groups,
      item.organisations ? item.organisations.map(elt => organisationAdapter.adapt(elt)) : [],
      item.organisation ? organisationAdapter.adapt(item.organisation) : undefined,
      item.locale,
      item.priceScaleCodes ? item.priceScaleCodes.toString() : undefined,
      item.customerGroups
    );
  }

  prepare(object: Customer): any {
    const target = {};
    Object.assign(target, object);
    target['priceScaleCodes'] = [object.priceScaleCode];

    return target;
  }
}
