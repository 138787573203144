import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {FilterSingleSelect} from "../../../lib/model/Filter";
import {FilterService} from "../../../lib/services/filter.service";

@Component({
  selector: 'app-filter-single-select',
  templateUrl: './filter-single-select.component.html',
  styleUrls: ['./filter-single-select.component.scss']
})
export class FilterSingleSelectComponent implements OnInit, OnDestroy {
  /**
   * Subscription to filter service to auto update the filter upon request.
   */
  private filterServiceSubscription: Subscription;
  /**
   * The filter to initialize the component with.
   */
  @Input() filter: FilterSingleSelect;
  /**
   * Emit the filter upon update.
   */
  @Output() filterUpdated: EventEmitter<FilterSingleSelect> = new EventEmitter();

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.filterServiceSubscription = this.filterService.filterSubject.subscribe((filter) => {
      if (filter.id === this.filter.id) {
        this.filter = <FilterSingleSelect>filter;
        this.filterUpdated.emit(this.filter);
      }
    });
  }

  ngOnDestroy() {
    this.filterServiceSubscription.unsubscribe();
  }

  updateSelected(id: string) {
    this.filter.value = id;
    this.filterUpdated.emit(this.filter);
  }
}
