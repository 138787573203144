import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { ComponentRef, Injectable } from '@angular/core';

interface DetachedRouteHandleExt extends DetachedRouteHandle {
  componentRef: ComponentRef<any>;
}

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  routesToCache: string[] = [];
  storedRouteHandles: Map<string, DetachedRouteHandleExt> = new Map<string, DetachedRouteHandleExt>();

  // Decides if the route should be stored
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute === true;
  }

  // Store the information for the route we're destructing
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandleExt): void {
    this.storedRouteHandles.set(route.url.toString(), handle);

    if (handle) {
      this.callHook(handle, 'ngOnDetach');
    }
  }

  // Return true if we have a stored route object for the next route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.storedRouteHandles.has(route.url.toString());
  }

  // If we returned true in shouldAttach(), now return the actual route data for restoration
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandleExt | null {
    const detachedTree = this.storedRouteHandles.get(route.url.toString());

    if (detachedTree) {
      this.callHook(detachedTree, 'ngOnAttach');
    }

    return detachedTree;
  }

  // Reuse the route if we're going to and from the same route
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig && !curr.data.forceReload;
  }

  private callHook(detachedTree: DetachedRouteHandleExt, hookName: string): void {
    const componentRef = detachedTree.componentRef;
    if (
      componentRef &&
      componentRef.instance &&
      typeof componentRef.instance[hookName] === 'function'
    ) {
      componentRef.instance[hookName]();
    }
  }
}
