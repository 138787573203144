import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remove-folder-confirm',
  templateUrl: './remove-folder-confirm.component.html',
  styleUrls: ['./remove-folder-confirm.component.scss'],
})
export class RemoveFolderConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
