import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from '../model/Item';
import { ProductVariant } from '../model/ProductVariant';
import { SaleChannel } from '../model/SaleChannel';
import { SystemeUTransaction, SystemeUTransactionAdapter } from '../model/SystemeUTransaction';
import { Transaction, TransactionAdapter } from '../model/Transaction';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {

  constructor(private http: HttpClient,
              private adapter: TransactionAdapter,
              private systemeuAdapter: SystemeUTransactionAdapter) {
  }

  /**
   * Returns not cancelled transactions for a sale channel.
   * @param saleChannel
   */
  public getTransactionsBySaleChannel(saleChannel: SaleChannel): Observable<Transaction[]> {
    let params = new HttpParams();
    params = params.set('currentStatus', ['CREATED', 'TO_VALIDATE', 'VALIDATED', 'PAID'].join(','))
                   .set('saleChannels', saleChannel.code)
                   .set('withInsufficientStocks', (saleChannel.type === 'capsule').toString());

    return this.http.get<Transaction[]>('/api/systemeu/transactions/light', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  /**
   * Get a single transaction from its id.
   * @param transactionId
   * @param withDeclinations
   * @param withInsufficientStocks
   */
  public getTransactionSystemeU(transactionId: string, withDeclinations: boolean = false, withInsufficientStocks: boolean = false): Observable<SystemeUTransaction> {
    let params = new HttpParams();
    params = params.set('computeItemQuantities', '1')
                   .set('withDeclinations', withDeclinations.toString())
                   .set('withInsufficientStocks', withInsufficientStocks.toString());

    return this.http.get<SystemeUTransaction>('/api/systemeu/transactions/' + transactionId, {params}).pipe(map(item => this.systemeuAdapter.adapt(item)));
  }

  /**
   * Create / update transaction.
   * @param transaction
   */
  public createOrUpdateTransaction(transaction: Transaction): Observable<Transaction> {
    let transactionId = '';
    if (!transaction.id) {
      transactionId = 'SYSU_';
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      transactionId += transaction.organisationId + '_' + Date.now() + randomNumber;
    } else {
      transactionId = transaction.id;
    }

    return this.http.put<Transaction>('/api/transactions/' + transactionId, this.adapter.prepare(transaction))
      .pipe(map(item => this.adapter.adapt(item)));
  }

  /**
   * Update full transaction and get Systeme U specific transaction format.
   * @deprecated Use updateModifiedItemsSystemU instead (lower cost).
   * @param transaction
   * @param withDeclinations
   * @param withInsufficientStocks
   */
  public updateTransactionSystemU(transaction: Transaction, withDeclinations: boolean = false, withInsufficientStocks: boolean = false): Observable<SystemeUTransaction> {
    let params = new HttpParams();
    params = params.set('computeItemQuantities', '1')
                   .set('withDeclinations', withDeclinations.toString());

    return this.http.put<SystemeUTransaction>('/api/systemeu/transactions/' + transaction.id, this.adapter.prepare(transaction), {params})
      .pipe(map(item => this.systemeuAdapter.adapt(item)));
  }

  /**
   * Update transaction (only modified items) and get Systeme U specific transaction format.
   * @param transaction
   * @param modifiedItems
   * @param withDeclinations
   * @param withInsufficientStocks
   */
  public updateModifiedItemsSystemU(transaction: Transaction, modifiedItems: Item[], withDeclinations: boolean = false, withInsufficientStocks: boolean = false): Observable<SystemeUTransaction> {
    let params = new HttpParams();
    params = params.set('computeItemQuantities', '1')
                   .set('withDeclinations', withDeclinations.toString())
                   .set('withInsufficientStocks', withInsufficientStocks.toString());

    return this.http.put<SystemeUTransaction>('/api/systemeu/transactions/' + transaction.id + '/items', modifiedItems, {params})
      .pipe(map(item => this.systemeuAdapter.adapt(item)));
  }

  /**
   * Update transaction status.
   * @param transaction
   * @param userId
   * @param status
   */
  public updateStatus(transaction: Transaction, userId: string, status: string): Observable<SystemeUTransaction> {
    let params = new HttpParams();
    params = params.set('computeItemQuantities', '1')
                   .set('withDeclinations', 'false');

    return this.http.post('/api/systemeu/transactions/' + transaction.id + '/status', {
      statusCode: status,
      userId,
    }, {params}).pipe(map(item => this.systemeuAdapter.adapt(item)));
  }

  /**
   * Add a product to a transaction.
   * @param transaction
   * @param product
   * @param quantity
   */
  public addProduct(transaction: Transaction, product: ProductVariant, quantity: number): Observable<any> {
    const params = new HttpParams();
    return this.http.post('/api/transactions/' + transaction.id + '/' + product.ean, {
      ean: product.ean,
      quantity,
    }, {params});
  }

  /**
   * Delete a product from a transaction.
   * @param transaction
   * @param declinationId
   */
  public deleteProduct(transaction: Transaction, declinationId: string): Observable<any> {
    const params = new HttpParams();
    return this.http.delete('/api/transactions/' + transaction.id + '/' + declinationId, {params});
  }
}
