import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class TransactionProperties {
  categoryCode: string;
  categoryName: string;
  rangeCode: string;
  rangeName: string;
  rangeRank: number;

  constructor(categoryCode: string, categoryName: string, rangeCode: string, rangeName: string, rangeRank: number) {
    this.categoryCode = categoryCode;
    this.categoryName = categoryName;
    this.rangeCode = rangeCode;
    this.rangeName = rangeName;
    this.rangeRank = rangeRank;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionPropertiesAdapter implements Adapter<TransactionProperties> {
  adapt(item: any): TransactionProperties {
    if (item) {
      return new TransactionProperties(
        item.categoryCode,
        item.categoryName,
        item.rangeCode,
        item.rangeName,
        item.rangeRank
      );
    } else {
      return undefined;
    }
  }
}
