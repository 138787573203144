<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="'Dossier merchandising'"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" title="Retour" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2>{{ sharedVariablesService.transaction?.properties.categoryName }} - {{ sharedVariablesService.transaction?.properties.rangeName }}
        - {{ sharedVariablesService.transaction?.customer.priceScaleCode }}</h2>
    </div>
    <div class="flex">
      <h2><span class="lighter">Du</span> {{ sharedVariablesService.saleChannel.openingDate | date : 'shortDate' }}
        <span class="lighter">au</span> {{ sharedVariablesService.saleChannel.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le classeur" title="Quitter le classeur" class="link" [routerLink]="['/tableau-de-bord', sharedVariablesService.saleChannel.code]"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<app-menu></app-menu>
<div class="planogram-page">
  <div id="planogram-container" (mouseleave)="hidePopoverAfterDelay()">
    <drag-scroll [scrollbar-hidden]="true" [drag-scroll-y-disabled]="(RATIO <= DEFAULT_RATIO)">
      <div id="planogram" [style.width.px]="nbElements * ELEMENT_WIDTH" [style.height.px]="ELEMENT_HEIGHT" #planogram>
        <div *ngFor="let planogramElement of planogramData">
          <div class="planogram-item" *ngFor="let planoItem of planogramElement.items"
          [ngClass]="{'readonly': !planoItem.productId}"
          [ngStyle]="{'bottom.px': planoItem.y * RATIO, 'left.px': planoItem.x * RATIO, 'height.px': planoItem.height * RATIO, 'width.px': planoItem.width * RATIO}"
          (mouseenter)="planoItem.productId ? showPopoverAfterDelay(planoItem) : undefined;" (mouseleave)="hidePopoverAfterDelay()" (click)="toggleInCart(planoItem)">
            <img *ngIf="planoItem.imageUrl" [src]="planoItem.imageUrl" alt=""/>
            <div class="id" *ngIf="planoItem.productId" [class.must-have]="planoItem.mustHave">
              {{ planoItem.declinationReference }}
            </div>
            <div class="checkbox" *ngIf="planoItem.productId"
                 [ngClass]="{ 'inCart': sharedVariablesService.transaction.containsDeclinationEan(planoItem.productId, planoItem.eans),
                              'checked': isChecked(planoItem),
                              'disabled': !transactionCanBeUpdated }">
            </div>
          </div>
        </div>

        <div id="popover" [ngClass]="{ 'visible': popoverVisible }" class="do-not-print"
             [ngStyle]="{'bottom.px': popoverBottom, 'left.px': popoverLeft}"
             (mouseenter)="keepPopover()" (mouseleave)="closePopover(false)">
          <app-product-item-small [forPlanogram]="true" [productId]="productId" [declinationId]="declinationId" (closePopover)="closePopover($event)"></app-product-item-small>
        </div>
      </div>
    </drag-scroll>
  </div>
  <div class="actions-wrapper">
    <div class="section">
      <div class="row">
        <div class="col-md-4">
          <button mat-button class="send-button small mt10" (click)="print()"><mat-icon aria-hidden="false" aria-label="Imprimer" class="font-size">print</mat-icon> Imprimer le dossier merchandising</button>
        </div>
        <div class="col-md-3 text-center">
          <button mat-mini-fab color="white" [disabled]="RATIO < 1" (click)="zoom(-0.3)" class="send-button ml20"><mat-icon aria-hidden="false" aria-label="Zoom out">zoom_out</mat-icon></button>
          <button mat-mini-fab color="white" [disabled]="RATIO === DEFAULT_RATIO" (click)="zoom(0)" class="send-button ml20"><mat-icon aria-hidden="false" aria-label="Fit">youtube_searched_for</mat-icon></button>
          <button mat-mini-fab color="white" [disabled]="RATIO > 4.5" (click)="zoom(+0.3)" class="send-button ml20"><mat-icon aria-hidden="false" aria-label="Zoom in">zoom_in</mat-icon></button>
        </div>
        <div class="col-md-5 text-right" *ngIf="transactionCanBeUpdated">
          <mat-checkbox (change)="toggleSelectAll()" [indeterminate]="indeterminate">Tout sélectionner</mat-checkbox>
          <button mat-raised-button color="accent" [disabled]="declinationIdsToAdd.size < 1" (click)="addToCart()" class="send-button ml20">
            <mat-icon aria-hidden="false" aria-label="Panier">add_shopping_cart</mat-icon>
            AJOUTER AU PANIER
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
