<div class="option-list">
  <div *ngFor="let option of options" class="choice" [ngStyle]="{'padding-left.px': level*7}">
    <div *ngIf="!multi" (click)="checkOption(option)" class="choice-box expanded level-{{level}}"
         [ngClass]="{'selected': (isSelected(option)), 'hidden': filterTerm && option.name.toLocaleLowerCase().indexOf(filterTerm) < 0}">
      <span class="label">{{ option.name }}</span>
    </div>
    <mat-checkbox *ngIf="multi" class="full-width expanded"
                  [checked]="isSelected(option)"
                  [ngClass]="!filterTerm || option.name.toLocaleLowerCase().indexOf(filterTerm) > -1 ? '' : 'hidden'"
                  (change)="checkOption(option)">{{ option.name }}</mat-checkbox>

    <div *ngIf="option.children?.length > 0" class="children">
      <app-option-list [multi]="multi" [options]="option.children" [level]="level+1"
                       (optionsSelected)="updateSelectedOptions($event)"
                       [selectedOptions]="selectedOptions"
                       [parentOption]="option"
                       (uncheckParent)="uncheckMyParent()"
                       [filterTerm]="filterTerm"></app-option-list>
    </div>
  </div>
</div>
