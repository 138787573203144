<nav class="breadcrumb">
  <div class="section">
    <div class="home item link" [routerLink]="['/salons']" title="HOME"><mat-icon>home</mat-icon></div>
    <div class="item link" *ngIf="saleChannel" [routerLink]="['/tableau-de-bord', saleChannel.code]">Salon {{ saleChannel.name }}</div>
    <div class="item link" *ngIf="saleChannel && transaction" [routerLink]="['/classeur', transaction.id]">
      <ng-container *ngIf="saleChannel.type === 'permanent' || saleChannel.type === 'bazar'">{{ transaction.properties.categoryName }}</ng-container>
      <ng-container *ngIf="saleChannel.type !== 'bazar'"> {{ transaction.properties.rangeName }} - {{ transaction.customer.priceScaleCode }}</ng-container>
    </div>
    <div class="item current-page" *ngIf="currentPage">{{ currentPage }}</div>
  </div>
</nav>
