import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthenticationService} from "../../lib/services/authentication.service";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public passwordForm: UntypedFormGroup;
  public loading: boolean = false;
  public email: string;
  private token: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private snackbar: MatSnackBar,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.email = this.route.snapshot.paramMap.get('email');
    this.token = this.route.snapshot.paramMap.get('token');

    this.passwordForm = new UntypedFormGroup({
      password: new UntypedFormControl(undefined, [Validators.minLength(8), Validators.required]),
      confirmPassword: new UntypedFormControl(undefined, [Validators.minLength(8), Validators.required]),
    });
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
      return;
    }

    if (this.passwordForm.get('password').value !== this.passwordForm.get('confirmPassword').value) {
      this.passwordForm.get('confirmPassword').reset();
      this.snackbar.open('La confirmation de mot de passe ne correspond pas.', undefined, {
        panelClass: ['error-snackbar'],
        duration: 2500,
      });
      return;
    }

    this.loading = true;
    const self = this;

    this.authenticationService.resetPassword(this.email, this.token, this.passwordForm.get('password').value).toPromise().then((result) => {
      self.snackbar.open('Votre mot de passe a été mis à jour avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
      self.loading = false;
      self.router.navigate(['/login']);
    }, (error) => {
      self.loading = false;
    });
  }
}
