<div id="planogram" [style.width.px]="nbElements * ELEMENT_WIDTH" [style.height.px]="ELEMENT_HEIGHT" *ngIf="planogramData">
  <div class="element" *ngFor="let planogramElement of planogramData" [ngStyle]="{'width.px': ELEMENT_WIDTH, 'height.px': ELEMENT_HEIGHT}"
  [class.page-break]="(planogramElement.elementNumber - 1) % 3 === 0">
    <strong> {{planogramElement.elementNumber}} </strong>
    <div class="planogram-item" *ngFor="let planoItem of planogramElement.items"
         [ngStyle]="{'bottom.px': planoItem.y * RATIO, 'left.px': (planoItem.x * RATIO - (planogramElement.elementNumber - 1) * ELEMENT_WIDTH), 'height.px': planoItem.height * RATIO, 'width.px': planoItem.width * RATIO}">
      <div *ngIf="planoItem.productId" class="id" [class.must-have]="planoItem.mustHave">
        {{ planoItem.declinationReference }}
      </div>
      <div class="checkbox" *ngIf="planoItem.productId" [ngClass]="{ 'inCart': sharedVariablesService.transaction.containsDeclinationEan(planoItem.productId, planoItem.eans) }"></div>
      <img *ngIf="planoItem.imageUrl" [src]="planoItem.imageUrl" alt="product" />
    </div>
  </div>
</div>
