import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Product } from './Product';

export class Price {
  id: string;
  marginRate: number;
  normalPrice: number;
  purchasingPrice: number;

  constructor(id: string, marginRate: number, normalPrice: number, purchasingPrice: number) {
    this.id = id;
    this.marginRate = marginRate;
    this.normalPrice = normalPrice;
    this.purchasingPrice = purchasingPrice;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PriceAdapter implements Adapter<Price> {
  adapt(item: any): Price {
    return new Price(item.id, item.marginRate, item.normalPrice, item.purchasingPrice);
  }
}
