import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Item, ItemAdapter } from './Item';
import { ProductDeclination, ProductDeclinationAdapter } from './ProductDeclination';
import { Transaction, TransactionAdapter } from './Transaction';

export class SystemeUTransactionDeclination {
  declination: ProductDeclination;
  variants: Item[];

  constructor(declination: ProductDeclination, variants: Item[]) {
    this.declination = declination;
    this.variants = variants;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SystemeUTransactionDeclinationAdapter implements Adapter<SystemeUTransactionDeclination> {
  adapt(item: any): SystemeUTransactionDeclination {
    const productDeclinationAdapter: ProductDeclinationAdapter = new ProductDeclinationAdapter();
    const itemAdapter: ItemAdapter = new ItemAdapter();

    return new SystemeUTransactionDeclination(
      productDeclinationAdapter.adapt(item.declination),
      item.variants.map((elt) => itemAdapter.adapt(elt))
    );
  }
}

export class SystemeUTransaction {
  public transaction: Transaction;
  public declinations: SystemeUTransactionDeclination[];
  public itemsWithInsufficientStocks: SystemeUTransactionDeclination[];

  constructor(transaction: Transaction, declinations: SystemeUTransactionDeclination[], itemsWithInsufficientStocks: SystemeUTransactionDeclination[]) {
    this.transaction = transaction;
    this.declinations = declinations;
    this.itemsWithInsufficientStocks = itemsWithInsufficientStocks;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SystemeUTransactionAdapter implements Adapter<SystemeUTransaction> {
  public adapt(item: any): SystemeUTransaction {
    const transactionAdapter: TransactionAdapter = new TransactionAdapter();
    const systemeUTransactionDeclinationAdapter: SystemeUTransactionDeclinationAdapter = new SystemeUTransactionDeclinationAdapter();

    return new SystemeUTransaction(
      transactionAdapter.adapt(item.transaction),
      item.items.map((elt) => systemeUTransactionDeclinationAdapter.adapt(elt)),
      item.itemsWithInsufficientStocks.map((elt) => systemeUTransactionDeclinationAdapter.adapt(elt))
    );
  }
}
