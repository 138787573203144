<div class="filter app-filter-multivalued-container full-width">
  <mat-label class="mat-label" (click)="toggleVisibility()">{{ filter.name }}
    <mat-icon class="link" *ngIf="!filter.visible">keyboard_arrow_right</mat-icon>
    <mat-icon class="link" *ngIf="filter.visible">keyboard_arrow_down</mat-icon>
  </mat-label>
  <div class="filter-content" [ngClass]="!filter.visible ? 'collapsed' : ''">
    <mat-form-field [ngClass]="filter.hint ? 'filter-hint' : ''">
      <mat-label>Filtrer</mat-label>
      <input matInput (input)="searchTerm.next($event.target.value)" autocomplete="off">
      <mat-hint *ngIf="filter.hint"><i>{{filter.hint}}</i></mat-hint>
    </mat-form-field>
    <mat-checkbox class="full-width" *ngFor="let filterOption of filter.options"
                [checked]="filter.selected.indexOf(filterOption.id) > -1"
                [ngClass]="!filterTerm || filterOption.name.toLocaleLowerCase().indexOf(filterTerm) > -1 ? '' : 'hidden'"
                (change)="updateSelected(filterOption.id, $event)">{{ filterOption.name }}</mat-checkbox>
  </div>
</div>
