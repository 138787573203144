import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../lib/model/User';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { ResetPasswordComponent } from '../../partials/reset-password/reset-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public organisationSelectionForm: UntypedFormGroup;
  public loading: boolean = false;
  public user: User;

  constructor(public dialog: MatDialog,
              public authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('code')) {
      const token = this.route.snapshot.queryParamMap.get('code');
      this.authenticationService.loginWithToken(token).toPromise().then((user) => {
        const subject = new Subject<User>();
        subject.next(user);

        this.authenticationService.currentUserSubject.next(user);

        if (user.organisations.length > 1) {
          this.user = user;
        } else {
          this.redirectAfterLogin();
        }
      });
    }

    this.loginForm = new UntypedFormGroup({
      login: new UntypedFormControl(undefined, Validators.required),
      password: new UntypedFormControl(undefined, Validators.required),
    });

    this.organisationSelectionForm = new UntypedFormGroup({
      organisation: new UntypedFormControl(undefined, Validators.required),
    });

    this.authenticationService.logout(false);
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const self = this;

    this.authenticationService.login(this.loginForm.get('login').value, this.loginForm.get('password').value).subscribe((user) => {
      const subject = new Subject<User>();
      subject.next(user);

      this.googleAnalyticsService.eventEmitter('login', { organisation: user.organisation.name });

      self.authenticationService.currentUserSubject.next(user);
      self.loading = false;

      if (user.organisations.length > 1) {
        this.user = user;
      } else {
        this.redirectAfterLogin();
      }
    }, (error) => {
      self.loading = false;
    });
  }

  openResetPasswordDialog(): void {
    this.googleAnalyticsService.eventEmitter('click_forgot_password');

    const dialogRef = this.dialog.open(ResetPasswordComponent, { width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * Organisation selection form processing.
   */
  onSubmitOrganisationSelect() {
    this.user.selectOrganisation(this.organisationSelectionForm.get('organisation').value);
    this.authenticationService.currentUserSubject.next(this.user);
    this.authenticationService.saveUser();
    // Redirect to exhibitions page
    this.router.navigate(['/salons']);
  }

  private redirectAfterLogin() {
    // If want to access a specific page after login, redirect
    if (this.route.snapshot.queryParamMap.get('returnUrl')) {
      this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl').valueOf()]);
    } else { // Otherwiser go to exhibitions page
      this.router.navigate(['/salons']);
    }
  }
}
