<h1 mat-dialog-title>Réinitialisation du mot de passe</h1>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <p>Veuillez indiquer l'adresse email de votre compte ci-dessous. Vous recevrez un email avec un lien vous permettant de modifier votre mot de passe.</p>
    <mat-form-field class="full-width">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" [value]="data?.email"/>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="mt5 mb5 text-center">
    <button type="button" mat-button [mat-dialog-close]>ANNULER</button>
    <button type="submit" mat-raised-button color="accent" [disabled]="!resetPasswordForm.valid">OK</button>
  </div>
</form>
