import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './lib/helpers/auth-guards';
import { CartComponent } from './pages/cart/cart.component';
import { FolderComponent } from './pages/folder/folder.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginAdminComponent } from './pages/login-admin/login-admin.component';
import { ProductComponent } from './pages/product/product.component';
import { ExhibitionsComponent } from './pages/exhibitions/exhibitions.component';
import { PlanogramComponent } from './pages/planogram/planogram.component';
import { PlanogramPrintComponent } from './pages/planogram-print/planogram-print.component';
import { PasswordComponent } from './pages/password/password.component';
import { DataGuard } from './lib/helpers/data-guards';
import { EditorialComponent } from './pages/editorial/editorial.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {animation: true},
  },
  {
    path: 'login-admin',
    component: LoginAdminComponent,
    data: {animation: true},
  },
  {
    path: 'reset-password/:email/:token',
    component: PasswordComponent,
  },
  {
    path: 'salons',
    component: ExhibitionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editorial/:salechannelcode',
    component: EditorialComponent,
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'tableau-de-bord/:salechannelcode',
    component: DashboardComponent,
    canActivate: [AuthGuard, DataGuard],
    data: {animation: true},
  },
  {
    path: 'classeur/:transactionid/planogramme',
    component: PlanogramComponent,
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid/planogramme/imprimer',
    component: PlanogramPrintComponent,
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid',
    component: FolderComponent,
    data: {reuseRoute: true},
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid/categorie/:categorycode',
    component: FolderComponent,
    data: {reuseRoute: true},
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid/marque/:brand',
    component: FolderComponent,
    data: {reuseRoute: true},
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid/recherche',
    component: FolderComponent,
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/fiche-produit/:ean/:declinationid',
    component: ProductComponent,
    data: {forceReload: true},
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/fiche-produit/:ean',
    component: ProductComponent,
    data: {forceReload: true},
    canActivate: [AuthGuard, DataGuard],
  },
  {
    path: 'classeur/:transactionid/panier',
    component: CartComponent,
    canActivate: [AuthGuard, DataGuard],
    data: {animation: true},
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
