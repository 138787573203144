import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { SystemeUTransaction, SystemeUTransactionDeclination } from '../../lib/model/SystemeUTransaction';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import { ProductItemComponent } from '../../partials/product-item/product-item.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public systemeUTransaction: SystemeUTransaction;
  public sortBy: string = 'asc';
  public canEditTransaction: boolean = false;
  public itemsWithInsufficientStocks: SystemeUTransactionDeclination[] = [];
  public fondDeRayon: boolean = false;
  private productDeclinationIdsWithInsufficientStocks: string[] = [];

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              public authenticationService: AuthenticationService,
              private dialog: MatDialog,
              private transactionService: TransactionService,
              private ngxLoaderService: NgxUiLoaderService,
              private snackbar: MatSnackBar,
              private location: Location,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.fondDeRayon = this.sharedVariablesService.saleChannel.isWishlist();
    this.ngxLoaderService.startBackground();

    const self = this;
    // Retrieve transaction with specific Systeme U format (by declination)
    this.transactionService.getTransactionSystemeU(this.route.snapshot.paramMap.get('transactionid'), true, (this.sharedVariablesService.saleChannel.type === 'capsule'))
      .pipe(take(1)).subscribe((systemeUTransaction) => {
      self.systemeUTransaction = systemeUTransaction;
      self.sharedVariablesService.transaction = systemeUTransaction.transaction;
      self.canEditTransaction = self.systemeUTransaction.transaction.currentStatus.code === 'CREATED' && self.sharedVariablesService.saleChannel.isOpen(true);
      this.computeInsufficientStocks();

      self.ngxLoaderService.stopBackground();
    }, (error) => {
      self.ngxLoaderService.stopBackground();
    });
  }

  /**
   * Opens a product view for the user to change its choices.
   */
  editProduct(productId: string, declinationId: string) {
    const self = this;
    const dialogRef = this.dialog.open(ProductItemComponent, {
      width: '730px',
      data: { popin: true, fromCart: true, productId, declinationId },
    });

    dialogRef.afterClosed().toPromise().then((data) => {
      if (data && data.systemeUTransaction) {
        self.updateTransaction(data.systemeUTransaction);
      }
    });
  }

  updateTransaction($event: SystemeUTransaction) {
    this.systemeUTransaction = $event;
    this.computeInsufficientStocks();
  }

  private computeInsufficientStocks() {
    this.productDeclinationIdsWithInsufficientStocks = [];
    this.itemsWithInsufficientStocks = this.systemeUTransaction.itemsWithInsufficientStocks;
    this.itemsWithInsufficientStocks.forEach((item) => {
      this.productDeclinationIdsWithInsufficientStocks.push(item.declination.id);
    });
  }

  /**
   * Move the transaction to TO_VALIDATE status
   */
  validateTransaction() {
    this.ngxLoaderService.start();

    const self = this;
    this.transactionService.updateStatus(this.systemeUTransaction.transaction, this.authenticationService.getCurrentUser().email, 'TO_VALIDATE').toPromise().then((systemeUTransaction) => {
      self.sharedVariablesService.transaction = systemeUTransaction.transaction;
      self.ngxLoaderService.stop();
      self.snackbar.open('Le classeur a été validé.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
      self.canEditTransaction = false;
    }, (err) => {
      this.ngxLoaderService.stop();
    });
  }

  goBack() {
    this.location.back();
  }

  /**
   * Tells whether a declinationId has stock issue.
   * @param declinationId
   */
  isStockInsufficient(declinationId: string) {
    if (this.productDeclinationIdsWithInsufficientStocks.length > 0) {
      return this.productDeclinationIdsWithInsufficientStocks.indexOf(declinationId) > -1;
    }
    return false;
  }

  finalize() {
    this.googleAnalyticsService.purchase(this.systemeUTransaction.transaction);
  }
}
