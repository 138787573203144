export class AnalyticsEcommerceItem {
  item_name: string;
  item_id: string;
  price: number;
  item_brand: string;
  item_category: string;
  item_list_name: string;
  item_list_id: string;
  index: number;
  quantity: number;
  item_variant: string;
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: string;


  constructor(item_name: string,
              item_id: string,
              price: number,
              item_brand: string,
              item_category: string,
              index: number,
              item_list_name?: string,
              item_list_id?: string,
              quantity: number = 1,
              item_variant?: string,
              promotion_id?: string,
              promotion_name?: string,
              creative_name?: string,
              creative_slot?: string) {
    this.item_name = item_name;
    this.item_id = item_id;
    this.price = price;
    this.item_brand = item_brand;
    this.item_category = item_category;
    this.item_list_name = item_list_name;
    this.item_list_id = item_list_id;
    this.index = index;
    this.quantity = quantity;
    this.item_variant = item_variant;
    this.promotion_id = promotion_id;
    this.promotion_name = promotion_name;
    this.creative_name = creative_name;
    this.creative_slot = creative_slot;
  }
}
