import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { Advertisement } from '../../lib/model/Advertisement';
import { SaleChannel } from '../../lib/model/SaleChannel';
import { AdvertisementService } from '../../lib/services/advertisement.service';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { ErrorService } from '../../lib/services/error.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SaleChannelService } from '../../lib/services/sale-channel.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-editorial',
  templateUrl: './editorial.component.html',
  styleUrls: ['./editorial.component.scss'],
})
export class EditorialComponent implements OnInit {
  public saleChannel: SaleChannel;
  public advertisements: Advertisement[] = [];
  public advertisementsOperation: Advertisement[] = [];
  public advertisementsCommandTool: Advertisement[] = [];
  public advertisementsCollectionPresentation: Advertisement[] = [];

  constructor(public router: Router,
              private route: ActivatedRoute,
              public authenticationService: AuthenticationService,
              public sharedVariablesService: SharedVariablesService,
              private advertisementService: AdvertisementService,
              private saleChannelService: SaleChannelService,
              private errorService: ErrorService,
              private ngxLoaderService: NgxUiLoaderService,
              private dialog: MatDialog,
              private location: Location,
              public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.saleChannel = this.sharedVariablesService.saleChannel;
    // Retrieve sale channel code from URL and fetch
    const code = this.route.snapshot.paramMap.get('salechannelcode');
    const self = this;

    this.ngxLoaderService.startLoader('loader-editorial');
    this.saleChannelService.getExhibition(code).pipe(take(1)).subscribe((saleChannel) => {
      if (saleChannel.exhibitionOpeningTime() > 0 && !self.authenticationService.getCurrentUser().isInstanceManager()) {
        self.errorService.notify('Ce salon est fermé.');
        self.router.navigate(['salons']);
      } else {
        self.saleChannel = saleChannel;
        // Fetch editorial advertisements for this sale channel
        const promise1 = self.advertisementService.getAdvertisements(self.saleChannel.code, 'EXHIBITION_EDITORIAL').toPromise().then((advertisements) => {
          self.advertisements = advertisements;

        });
        const promise2 = self.advertisementService.getAdvertisements(self.saleChannel.code, 'EXHIBITION_OPERATION_MODE').toPromise().then((advertisements) => {
          self.advertisementsOperation = advertisements;

        });
        const promise3 = self.advertisementService.getAdvertisements(self.saleChannel.code, 'EXHIBITION_COMMAND_TOOL').toPromise().then((advertisements) => {
          self.advertisementsCommandTool = advertisements;

        });
        const promise4 = self.advertisementService.getAdvertisements(self.saleChannel.code, 'EXHIBITION_COLLECTION_PRESENTATION').toPromise().then((advertisements) => {
          self.advertisementsCollectionPresentation = advertisements;

        });

        Promise.all([promise1, promise2, promise3, promise4]).then((result) => {
          this.ngxLoaderService.stopLoader('loader-editorial');
          // Check there are advertisements
          if (self.advertisements.length + self.advertisementsOperation.length +
              self.advertisementsCommandTool.length + self.advertisementsCollectionPresentation.length === 0) {
            self.goToDashboard();
          }
        });
      }
    }, (error) => {
      this.router.navigate(['tableau-de-bord', this.saleChannel.code]);
    });
  }

  public goToDashboard() {
    this.googleAnalyticsService.eventEmitter('click_proceed_with_exhibition', { exhibitionName: this.saleChannel.name });
    this.router.navigate(['tableau-de-bord', this.saleChannel.code]);
  }

  isKeyneosoftHosted(redirectionUrl: string) {
    return (redirectionUrl && redirectionUrl.includes('keyneosoft'));
  }

  buildRedirectionUrl(redirectionUrl: string): string {
    const excludedTypes = ['.mp4', '.MP4'];
    // For now, mp4 cause 404 if token is present in URL, so don't had it. SYS-327
    if (this.isKeyneosoftHosted(redirectionUrl) && !excludedTypes.some(v => redirectionUrl.includes(v))) {
      return redirectionUrl + '&token=' + this.authenticationService.getCurrentUser().token;
    } else {
      return redirectionUrl;
    }
  }

  clickMedia(advertisement: Advertisement) {
    this.googleAnalyticsService.eventEmitter('click_media_file',
      {
        exhibitionName: this.saleChannel.name,
        mediaUrl: advertisement.redirectionUrl,
        mediaName: advertisement.name,
        organisation: this.authenticationService.getCurrentUser().organisation.name
      });
  }
}
