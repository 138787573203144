import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterMultipleChoiceTree, FilterOption, FilterSingleChoiceTree } from '../../../lib/model/Filter';
import { FilterService } from '../../../lib/services/filter.service';

@Component({
  selector: 'app-filter-tree-choice',
  templateUrl: './filter-tree-choice.component.html',
  styleUrls: ['./filter-tree-choice.component.scss']
})
export class FilterTreeChoiceComponent implements OnInit, OnDestroy {
  /**
   * Subscription to filter service to auto update the filter upon request.
   */
  private filterServiceSubscription: Subscription;
  /**
   * The filter to initialize the component with.
   */
  @Input() filter: FilterSingleChoiceTree|FilterMultipleChoiceTree;
  /**
   * Emit the filter upon update.
   */
  @Output() filterUpdated: EventEmitter<FilterSingleChoiceTree|FilterMultipleChoiceTree> = new EventEmitter();
  /**
   * The user input for search.
   */
  searchTerm: Subject<string> = new Subject<string>();
  filterTerm: string;

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.filterServiceSubscription = this.filterService.filterSubject.subscribe((filter) => {
      if (filter.id === this.filter.id) {
        this.filter = <FilterSingleChoiceTree>filter;
        this.filterUpdated.emit(this.filter);
      }
    });

    this.searchTerm.pipe(debounceTime(400)).subscribe((data) => {
      this.filterTerm = data.toLocaleLowerCase();
    });
  }

  ngOnDestroy() {
    this.filterServiceSubscription.unsubscribe();
  }

  toggleVisibility() {
    this.filter.visible = !this.filter.visible;
  }

  isMultichoice(): boolean {
    return this.filter.getType() === 'FilterMultipleChoiceTree';
  }

  updateSelected(options: string[]) {
    if (this.filter.getType() === 'FilterSingleChoiceTree') {
      if (options.length === 0) {
        // @ts-ignore
        this.filter.value = undefined;
      } else {
        // @ts-ignore
        this.filter.value = options[0];
      }
    } else { // FilterMultipleChoiceTree
      // @ts-ignore
      this.filter.selected = options;
    }

    this.filterUpdated.emit(this.filter);
  }
}
