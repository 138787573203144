import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SharedVariablesService} from "../../lib/services/shared-variables.service";
import {Location} from "@angular/common";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {ProductService} from "../../lib/services/product.service";
import {PlanogramElement, PlanogramItem} from "../../lib/model/Planogram";
import {Item} from "../../lib/model/Item";
import {take} from "rxjs/operators";
import {TransactionService} from "../../lib/services/transaction.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-planogram',
  templateUrl: './planogram.component.html',
  styleUrls: ['./planogram.component.scss'],
})
export class PlanogramComponent implements OnInit {
  @ViewChild('planogram', {static: true, read: ElementRef}) planogramElt: ElementRef;
  public readonly NATURAL_WIDTH: number = 133;
  public readonly NATURAL_HEIGHT: number = 266;
  public readonly DEFAULT_RATIO: number = 3.067669173; // 816px / 266cm
  public ELEMENT_WIDTH: number = 408; // 408px de large = 133cm x ratio
  public ELEMENT_HEIGHT: number = 816; // 816px de haut = (220 cm + 46cm de marge) x ratio
  public RATIO: number = this.DEFAULT_RATIO; // 816px / 266cm
  public readonly POPOVER_SHOW_DELAY: number = 1000;
  public readonly POPOVER_HIDE_DELAY: number = 1000;
  public readonly POPOVER_WIDTH: number = 250;
  public readonly POPOVER_HEIGHT: number = 380;
  public nbElements: number = 1;
  public planogramData: PlanogramElement[] = [];
  // Popover product ID
  public productId: string = undefined;
  // Popover declination ID
  public declinationId: string = undefined;
  public popoverVisible: boolean = false;
  public popoverBottom: number = 0;
  public popoverLeft: number = 0;
  public indeterminate: boolean = false;
  public declinationIdsToAdd: Set<string> = new Set();
  public transactionCanBeUpdated: boolean = true;
  private popoverShowTimer: any;
  private popoverHideTimer: any;
  private allSelected: boolean = false;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private location: Location,
              private ngxLoaderService: NgxUiLoaderService,
              private transactionService: TransactionService,
              private productService: ProductService,
              private snackbar: MatSnackBar) {

  }

  ngOnInit() {
    const self = this;

    this.sharedVariablesService.loadFullTransaction(this.route.snapshot.paramMap.get('transactionid')).then((transaction) => {
      self.transactionCanBeUpdated = transaction.currentStatus.code === 'CREATED' && self.sharedVariablesService.saleChannel.isOpen(true);
      // We compute the number of elements
      const match = this.sharedVariablesService.transaction.properties.rangeName.match(/(\d+,?\d?).*/i);
      if (match.length > 1) {
        this.nbElements = Math.ceil(Number(match[1]));
      }
      self.ngxLoaderService.start();
      // Call WS for planogram data
      this.productService.getPlanogram(transaction.properties.categoryCode, transaction.properties.rangeCode).toPromise().then((planogram) => {
        this.planogramData = planogram.elements;
        self.ngxLoaderService.stop();
      });
    });
  }

  /**
   * Go back in the navigation stack.
   */
  goBack() {
    this.location.back();
  }

  /**
   * Show popover for product after a delay.
   * @param planogramItem
   */
  showPopoverAfterDelay(planogramItem: PlanogramItem) {
    clearInterval(this.popoverShowTimer);
    this.popoverShowTimer = setTimeout(this.showPopover.bind(this), this.POPOVER_SHOW_DELAY, planogramItem);
  }

  /**
   * Show popover for product.
   * @param planogramItem
   */
  showPopover(planogramItem: PlanogramItem) {
    this.keepPopover();
    // /!\ We FIRST set declination ID, as product-item-small triggers product loading when [productId] is changed (via setter)
    this.declinationId = planogramItem.declinationId;
    this.productId = planogramItem.productId;
    // We compute position and display popover
    // 1.  We determine whether the product is in first element (popover will be on the left, on the right otherwise)
    if (Number(planogramItem.x) < this.ELEMENT_WIDTH ||
      planogramItem.x * this.RATIO - this.POPOVER_WIDTH - this.planogramElt.nativeElement.parentElement.scrollLeft < 200) {
      this.popoverLeft = this.planogramElt.nativeElement.offsetLeft + planogramItem.x * this.RATIO + planogramItem.width * this.RATIO * 1.2 - this.planogramElt.nativeElement.parentElement.scrollLeft;
    } else {
      this.popoverLeft = this.planogramElt.nativeElement.offsetLeft + planogramItem.x * this.RATIO - this.POPOVER_WIDTH - this.planogramElt.nativeElement.parentElement.scrollLeft;
    }
    // 2. For vertical align, center vertically or push up if item is near page bottom
    if (planogramItem.y * this.RATIO > this.POPOVER_HEIGHT / 2) {
      this.popoverBottom = planogramItem.y * this.RATIO - this.POPOVER_HEIGHT / 2 + window.scrollY -
        (this.planogramElt.nativeElement.parentElement.scrollHeight - this.planogramElt.nativeElement.parentElement.clientHeight - this.planogramElt.nativeElement.parentElement.scrollTop);
    } else {
      this.popoverBottom = planogramItem.y * this.RATIO - 20 + window.scrollY -
        (this.planogramElt.nativeElement.parentElement.scrollHeight - this.planogramElt.nativeElement.parentElement.clientHeight - this.planogramElt.nativeElement.parentElement.scrollTop);
    }
    this.popoverVisible = true;
  }

  /**
   * Hide popover for product after a delay.
   */
  hidePopoverAfterDelay() {
    this.popoverHideTimer = setTimeout(this.closePopover.bind(this), this.POPOVER_HIDE_DELAY, false);
  }

  keepPopover() {
    clearInterval(this.popoverHideTimer);
    clearInterval(this.popoverShowTimer);
  }

  closePopover(resetProduct: boolean) {
    this.popoverVisible = false;
    this.popoverLeft = -10000;
    if (resetProduct) {
      this.productId = undefined;
      this.declinationId = undefined;
    }
  }

  toggleInCart(planogramItem: PlanogramItem) {
    if (planogramItem.productId && planogramItem.declinationId) {
      // If eans must not be added to cart
      if (this.declinationIdsToAdd.has(planogramItem.declinationId)) {
        this.declinationIdsToAdd.delete(planogramItem.declinationId);
        this.indeterminate = this.allSelected;
      } else if (!this.sharedVariablesService.transaction.containsDeclinationEan(planogramItem.productId, planogramItem.eans)) {
        // Eans must be added
        this.declinationIdsToAdd.add(planogramItem.declinationId);
      }
    }
  }

  /**
   * Return true if a planogram item is checked to be added to the cart.
   * @param planogramItem
   */
  isChecked(planogramItem: PlanogramItem) {
    return this.declinationIdsToAdd.has(planogramItem.declinationId);
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.declinationIdsToAdd.clear();
    } else {
      this.planogramData.forEach((element) => {
        element.items.forEach((planogramItem) => {
          if (!this.sharedVariablesService.transaction.containsDeclinationEan(planogramItem.productId, planogramItem.eans)) {
            this.declinationIdsToAdd.add(planogramItem.declinationId);
          }
        });
      });
    }
    this.allSelected = !this.allSelected;
    this.indeterminate = false;
  }

  /**
   * Add selected declinationIds products to transaction.
   */
  addToCart() {
    this.ngxLoaderService.start();

    const eans: Set<string> = new Set();
    // We go throught the planogram data to find out eans to add
    this.planogramData.forEach((element) => {
      element.items.forEach((planogramItem) => {
        if (this.declinationIdsToAdd.has(planogramItem.declinationId)) {
          planogramItem.eans.forEach(ean => {
            eans.add(ean);
          });
        }
      });
    });

    const modifiedItems: Item[] = [];
    eans.forEach((ean) => {
      modifiedItems.push(<Item>{ean, quantity: 1});
    });

    if (eans.size > 0) {
      const self = this;

      this.transactionService.updateModifiedItemsSystemU(this.sharedVariablesService.transaction, modifiedItems,
        false).pipe(take(1)).subscribe((systemeUTransaction) => {
        self.sharedVariablesService.transaction = systemeUTransaction.transaction;
        self.snackbar.open('Panier mis à jour avec succès.', undefined, {
          panelClass: ['success-snackbar'],
          duration: 2500,
        });
        this.declinationIdsToAdd.clear();
        this.ngxLoaderService.stop();
      });
    }
  }

  zoom(add: number = 0) {
    this.setRatio(add === 0 ? this.DEFAULT_RATIO : this.RATIO + add);
  }

  private setRatio(ratio: number) {
    this.RATIO = ratio;
    this.ELEMENT_WIDTH = this.NATURAL_WIDTH * this.RATIO;
    this.ELEMENT_HEIGHT = this.NATURAL_HEIGHT * this.RATIO;
  }

  print() {
    window.open(this.router.createUrlTree(['/classeur', this.sharedVariablesService.transaction.id, 'planogramme', 'imprimer']).toString(),
      'Imprimer le dossier merchandising',
      'menubar=no, scrollbars=no, top=100, left=200, width=980, height=650');
  }
}
