import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { Advertisement } from '../../lib/model/Advertisement';
import { Category, CategoryRange } from '../../lib/model/Category';
import { Customer } from '../../lib/model/Customer';
import { SaleChannel } from '../../lib/model/SaleChannel';
import { Transaction } from '../../lib/model/Transaction';
import { TransactionProperties } from '../../lib/model/TransactionProperties';
import { AdvertisementService } from '../../lib/services/advertisement.service';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CategoryService } from '../../lib/services/category.service';
import { ErrorService } from '../../lib/services/error.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SaleChannelService } from '../../lib/services/sale-channel.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import { RemoveFolderConfirmComponent } from '../../partials/remove-folder-confirm/remove-folder-confirm.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public saleChannel: SaleChannel;
  public categories: Category[];
  public elements: CategoryRange[] = [];
  public transactions: Transaction[] = [];
  public form: UntypedFormGroup;
  public formCapsule: UntypedFormGroup;
  public formBazar: UntypedFormGroup;
  public loadingCreate: boolean;
  // CA Vente TTC
  public totalAmount: number = 0;
  // CA Cession HT
  public totalPurchasingAmount: number = 0;
  // Tx marge brute
  public totalMarginRate: number = 0;
  // Qté UVC
  public totalSalesUnitCount: number = 0;
  // Whether some transactions can be sent (TO_VALIDATE)
  public orderIsOkToBeSent: boolean = false;
  public printUrl: string;
  public advertisement: Advertisement;
  public atLeastOneOrderToSend: boolean = false;

  constructor(public router: Router,
              public authenticationService: AuthenticationService,
              public sharedVariablesService: SharedVariablesService,
              private route: ActivatedRoute,
              private saleChannelService: SaleChannelService,
              private transactionService: TransactionService,
              private categoryService: CategoryService,
              private advertisementService: AdvertisementService,
              private errorService: ErrorService,
              private ngxLoaderService: NgxUiLoaderService,
              private dialog: MatDialog,
              private location: Location,
              private snackbar: MatSnackBar,
              public googleAnalyticsService: GoogleAnalyticsService) {

    this.form = new UntypedFormGroup({
      assortiment: new UntypedFormControl(undefined, [Validators.required]),
      elts: new UntypedFormControl({value: undefined, disabled: true}, [Validators.required]),
      tarif: new UntypedFormControl(undefined, [Validators.required]),
    });

    this.formCapsule = new UntypedFormGroup({
      elts: new UntypedFormControl(undefined, [Validators.required]),
    });

    this.formBazar = new UntypedFormGroup({
      assortiment: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  ngOnInit() {
    // Set transaction as undefined in shared variables
    this.sharedVariablesService.transaction = undefined;
    this.saleChannel = this.sharedVariablesService.saleChannel;

    // Retrieve sale channel code from URL and fetch
    const code = this.route.snapshot.paramMap.get('salechannelcode');
    const self = this;

    this.saleChannelService.getExhibition(code).pipe(take(1)).subscribe((saleChannel) => {
      // Allow access to admin to an exhibition in the future
      if (saleChannel.exhibitionOpeningTime() > 0 && !self.authenticationService.getCurrentUser().isInstanceManager()) {
        self.errorService.notify('Ce salon est fermé.');
        self.router.navigate(['salons']);
      } else {
        self.saleChannel = saleChannel;
        self.getTransactionsForSaleChannel();

        // If permanent
        if (self.saleChannel.type === 'permanent' || self.saleChannel.type === 'bazar') {
          // Fetch categories for this sale channel
          self.categoryService.getCategoriesBySaleChannel(self.saleChannel).pipe(take(1)).subscribe((categories) => {
            self.categories = categories;
          });
        } else {
          self.categoryService.getRangesBySaleChannel(self.saleChannel).toPromise().then((elements) => {
            self.elements = elements;
          });
        }

        // Fetch advertisement banner for this exhibition
        this.advertisementService.getAdvertisements(saleChannel.code, 'EXHIBITION_BANNER').toPromise().then((advertisements) => {
          if (advertisements.length > 0) {
            self.advertisement = advertisements[0];
          }
        });
      }
    }, (error) => {
      self.router.navigate(['salons']);
    });
  }

  /**
   * Fetch folders (transaction) for this sale channel.
   */
  getTransactionsForSaleChannel() {
    const self = this;

    this.ngxLoaderService.startLoader('loader-transactions');
    this.transactionService.getTransactionsBySaleChannel(this.saleChannel).pipe(take(1)).subscribe((transactions) => {
      self.transactions = transactions;
      self.computeTotalSummary();

      self.printUrl = '/api/systemeu/transactions/pdf?token=' + this.authenticationService.getCurrentUser().token
        + '&transactionIds=' + self.transactions.reduce<string[]>((list: string[], transaction: Transaction) => {
        list.push(transaction.id);
        return list;
      }, []).join();
      self.ngxLoaderService.stopLoader('loader-transactions');
    });
  }

  /**
   * Update element selector (in create folder form) with values from selected category.
   * @param category
   */
  updateElements(category: Category) {
    this.form.get('elts').setValue(undefined);
    this.form.get('elts').disable();
    this.elements = [];
    this.elements = category.ranges;
    if (this.elements.length > 0) {
      this.form.get('elts').enable();
    }
    this.form.updateValueAndValidity();
  }

  /**
   * Create a transaction from form values.
   */
  createTransaction() {
    this.loadingCreate = true;
    const self = this;

    const transaction = new Transaction(
      undefined,
      this.saleChannel.code,
      Customer.createFromUserOrCustomer(this.authenticationService.getCurrentUser(), this.form.get('tarif').value),
      new TransactionProperties(this.form.get('assortiment').value.code, this.form.get('assortiment').value.name, this.form.get('elts').value.code, this.form.get('elts').value.name, this.form.get('elts').value.rank),
      this.authenticationService.getCurrentUser().organisation.id,
      this.saleChannel.code,
      'website'
    );

    this.googleAnalyticsService.eventEmitter('click_create_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    // Call service to create transaction
    this.transactionService.createOrUpdateTransaction(transaction).pipe(take(1)).subscribe((transaction) => {
      self.getTransactionsForSaleChannel();
      self.loadingCreate = false;
      self.snackbar.open('Classeur créé avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, (error) => {
      self.loadingCreate = false;
    });
  }

  /**
   * Create a capsule transaction from form values.
   */
  createCapsuleTransaction() {
    this.loadingCreate = true;
    const self = this;

    const transaction = new Transaction(
      undefined,
      this.saleChannel.code,
      Customer.createFromUserOrCustomer(this.authenticationService.getCurrentUser(), 'T1'),
      new TransactionProperties(undefined, undefined, this.formCapsule.get('elts').value.code, this.formCapsule.get('elts').value.name, this.formCapsule.get('elts').value.rank),
      this.authenticationService.getCurrentUser().organisation.id,
      this.saleChannel.code,
      'website'
    );

    this.googleAnalyticsService.eventEmitter('click_create_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    // Call service to create transaction
    this.transactionService.createOrUpdateTransaction(transaction).pipe(take(1)).subscribe((transaction) => {
      self.getTransactionsForSaleChannel();
      self.loadingCreate = false;
      self.snackbar.open('Classeur créé avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, (error) => {
      self.loadingCreate = false;
    });
  }

  /**
   * Create a bazar transaction from form values.
   */
  createBazarTransaction() {
    this.loadingCreate = true;
    const self = this;

    const transaction = new Transaction(
      undefined,
      this.saleChannel.code,
      Customer.createFromUserOrCustomer(this.authenticationService.getCurrentUser(), 'PVC_TRACT'),
      new TransactionProperties(this.formBazar.get('assortiment').value.code, this.formBazar.get('assortiment').value.name, undefined, undefined, undefined),
      this.authenticationService.getCurrentUser().organisation.id,
      this.saleChannel.code,
      'website'
    );

    this.googleAnalyticsService.eventEmitter('click_create_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    // Call service to create transaction
    this.transactionService.createOrUpdateTransaction(transaction).pipe(take(1)).subscribe((transaction) => {
      self.getTransactionsForSaleChannel();
      self.loadingCreate = false;
      self.snackbar.open('Classeur créé avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, (error) => {
      self.loadingCreate = false;
    });
  }

  /**
   * Open a folder.
   */
  openFolder(transaction: Transaction) {
    // Set transaction in shared variables
    this.sharedVariablesService.transaction = transaction;

    this.googleAnalyticsService.eventEmitter('select_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    if (this.sharedVariablesService.saleChannel.type === 'permanent') {
      this.router.navigate(['classeur', transaction.id, 'planogramme']);
    } else {
      // If stock issue, navigate directly to cart
      if (transaction.insufficientStock) {
        this.router.navigate(['classeur', transaction.id, 'panier']);
      } else {
        this.router.navigate(['classeur', transaction.id]);
      }
    }
  }


  /**
   * Compute sums of all transactions and global status.
   */
  computeTotalSummary() {
    this.totalAmount = 0;
    this.totalPurchasingAmount = 0;
    this.totalMarginRate = 0;
    this.totalSalesUnitCount = 0;
    this.orderIsOkToBeSent = false;
    this.atLeastOneOrderToSend = false;

    let i = 0;
    this.transactions.forEach((transaction) => {
      i++;
      this.totalAmount += transaction.totalAmount;
      this.totalPurchasingAmount += transaction.totalPurchasingAmount;
      this.totalSalesUnitCount = this.totalSalesUnitCount + (transaction.totalSalesUnitCount ? transaction.totalSalesUnitCount : 0);
      // Button to "send" transaction enabled when there is at least on in TO_VALIDATE status
      this.orderIsOkToBeSent = this.orderIsOkToBeSent || transaction.currentStatus.code === 'TO_VALIDATE';
      this.atLeastOneOrderToSend = this.atLeastOneOrderToSend || transaction.currentStatus.code !== 'VALIDATED';
    });

    this.totalMarginRate = ((this.totalAmount / 1.2) - this.totalPurchasingAmount) / (this.totalAmount / 1.2);
  }

  /**
   * Delete folder.
   * @param transaction
   */
  deleteFolder(transaction: Transaction) {
    const dialogRef = this.dialog.open(RemoveFolderConfirmComponent);
    const self = this;

    this.googleAnalyticsService.eventEmitter('click_delete_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        self.transactionService.updateStatus(transaction, this.authenticationService.getCurrentUser().email, 'CANCELED')
          .toPromise().then(() => {
          self.getTransactionsForSaleChannel();
          self.snackbar.open('Classeur supprimé avec succès.', undefined, {
            panelClass: ['success-snackbar'],
            duration: 2500,
          });
        });
      }
    });
  }

  /**
   * Change status to VALIDATED for all TO_VALIDATE transactions.
   */
  finalizeOrder() {
    this.ngxLoaderService.startLoader('loader-transactions');

    this.googleAnalyticsService.eventEmitter('click_finalize_order', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    const self = this;
    const size = this.transactions.length;
    for (let i = 0; i < size; i++) {
      const transaction = this.transactions[i];
      if (transaction.currentStatus.code === 'TO_VALIDATE') {
        self.transactionService.updateStatus(transaction, this.authenticationService.getCurrentUser().email, 'VALIDATED')
          .toPromise().then(() => {
            if (i === size - 1) {
              self.ngxLoaderService.stopLoader('loader-transactions');
              self.getTransactionsForSaleChannel();
            }
        });
      }
    }
  }

  /**
   * Re-open a transaction.
   * @param transaction
   */
  reopenFolder(transaction: Transaction) {
    this.ngxLoaderService.startLoader('loader-transactions');

    this.googleAnalyticsService.eventEmitter('click_reopen_folder', { exhibitionName: this.sharedVariablesService.saleChannel.name });

    const self = this;
    self.transactionService.updateStatus(transaction, this.authenticationService.getCurrentUser().email, 'CREATED').toPromise().then(() => {
      self.ngxLoaderService.stopLoader('loader-transactions');
      self.getTransactionsForSaleChannel();
    });
  }

  /**
   * Go back action.
   */
  goBack() {
    this.location.back();
  }
}
