import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, CategoryAdapter, CategoryRange } from '../model/Category';
import { SaleChannel } from '../model/SaleChannel';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  constructor(private http: HttpClient, private adapter: CategoryAdapter) {}

  /**
   * Returns level 1 categories for a sale channel.
   * @param saleChannel
   */
  public getCategoriesBySaleChannel(saleChannel: SaleChannel): Observable<Category[]> {
    let params = new HttpParams();
    params = params.set('level', '1')
                   .set('saleChannelCode', saleChannel.code);

    return this.http.get<Category[]>('/api/categories', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  /**
   * Returns categories tree.
   * @param categoryCode
   */
  public getCategoriesTree(categoryCode: string): Observable<Category> {
    const params = new HttpParams();

    return this.http.get<Category>('/api/categories/tree/' + categoryCode, {params}).pipe(map(item => this.adapter.adapt(item)));
  }

  /**
   * Returns categories for a capsule exhibition.
   * @param saleChannelCode
   * @param rangeCode
   */
  public getCategoriesByRange(saleChannelCode: string, rangeCode: string): Observable<Category[]> {
    const params = new HttpParams();

    return this.http.get<Category[]>('/api/systemeu/' + saleChannelCode + '/categories/' + rangeCode, {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  /**
   * Returns ranges for a capsule sale channel.
   * @param saleChannel
   */
  public getRangesBySaleChannel(saleChannel: SaleChannel): Observable<CategoryRange[]> {
    return this.http.get<CategoryRange[]>('/api/systemeu/' + saleChannel.code + '/ranges');
  }
}
