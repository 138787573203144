<div class="top-header section row">
  <div class="col-sm-6 col-xs-12">
    <div class="row">
      <div class="col-sm-5">
        <a [routerLink]="sharedVariablesService.saleChannel ? ['/tableau-de-bord', sharedVariablesService.saleChannel?.code] : ['/salons']">
          <img class="logo" src="/assets/images/small_system_u_salon.png" alt="Système U - Salons web"/>
        </a>
      </div>
      <div class="col-sm-6 pl20 pr0" *ngIf="sharedVariablesService.transaction">
        <form class="flex" [formGroup]="searchForm" (ngSubmit)="search()">
          <mat-form-field class="full-width mt10">
            <input matInput placeholder="Nom produit, {{ sharedVariablesService.saleChannel.isWishlist() ? 'n° présentation...' : 'n° photo...' }}" type="text" value="" formControlName="search">
            <button mat-icon-button matIconSuffix type="submit" class="small" [disabled]="!searchForm.valid"><mat-icon aria-label="Rechercher">search</mat-icon></button>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xs-12">
    <div class="float-right full-width">
      <div class="row">
        <div class="col-sm-4" *ngIf="!sharedVariablesService.transaction"></div>
        <div class="col-sm-4 user-infos">
          <div>
            <div><strong>{{ (user.isInstanceManager() ? 'Administrateur' : (user.isStoreManager() ? 'Responsable Magasin' : 'Responsable Textile'))}}</strong></div>
            <div class="store mb10">{{ user.organisation.name }}</div>
            <div><mat-icon aria-hidden="false" aria-label="Compte utilisateur" class="font-size">account_circle</mat-icon> {{ user.firstName }}</div>
          </div>
        </div>
        <div class="col-sm-4">
          <button mat-button class="small" color="accent" (click)="logout()"><mat-icon class="font-size" aria-hidden="false" aria-label="Compte utilisateur">exit_to_app</mat-icon> Se déconnecter</button>
          <button *ngIf="!user.isInstanceManager()" mat-button class="small" (click)="openResetPasswordDialog()"><mat-icon class="font-size" aria-hidden="false" aria-label="Mot de passe">lock</mat-icon> Modifier mot de passe</button>
          <div class="mt10">
            <button mat-button class="small" color="primary" *ngIf="sharedVariablesService.saleChannel" (click)="openContactDialog()"><mat-icon class="font-size" aria-hidden="false" aria-label="Contact">mail_outline</mat-icon> Contact</button>
            <a mat-button class="small" color="primary" target="_blank" *ngIf="helpFile" href="{{ buildRedirectionUrl(helpFile.redirectionUrl) }}"><mat-icon class="font-size" aria-hidden="false" aria-label="Aide">help_outline</mat-icon> Aide</a>
          </div>
          <div *ngIf="sizeGuide">
            <a mat-button class="small" color="primary" target="_blank" href="{{ buildRedirectionUrl(sizeGuide.redirectionUrl) }}"><mat-icon class="font-size" aria-hidden="false" aria-label="Guide des tailles" svgIcon="measure-construction"></mat-icon> Guide des tailles</a>
          </div>
        </div>
        <div class="col-sm-4" *ngIf="sharedVariablesService.transaction">
          <button mat-button color="accent" class="cartButton mt10" [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'panier']"
            (click)="googleAnalyticsService.beginCheckout(sharedVariablesService.transaction)"><mat-icon aria-hidden="false" aria-label="Panier">shopping_cart</mat-icon>
            <span *ngIf="!sharedVariablesService.saleChannel.isWishlist()">Mon panier</span>
            <span *ngIf="sharedVariablesService.saleChannel.isWishlist()">Ma sélection</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
