<div class="product">
  <div class="row">
    <div class="col-md-1 no-gutters" *ngIf="sysDeclination.declination.imageUrl">
      <div class="product-image">
        <img src="{{ sysDeclination.declination.imageUrl }}" alt="Product">
      </div>
    </div>
    <div class="col-md-11">
      <h3 class="link" [routerLink]="['/classeur/fiche-produit', sysDeclination.declination.productId, sysDeclination.declination.id]">{{ sysDeclination.declination.name }}</h3>
      <div *ngIf="!sharedVariablesService.saleChannel.isWishlist()">N° {{ sysDeclination.declination.reference }}</div>
      <div *ngIf="sharedVariablesService.saleChannel.isWishlist()">N° de présentation {{ sysDeclination.variants[0]?.productVariant.sku }}</div>
      <div class="conditionning">Cdt : <strong class="accent">{{ sysDeclination.declination.salesUnitCount }}</strong></div>
    </div>
  </div>
  <div class="row mt10" *ngIf="this.sharedVariablesService.saleChannel.type !== 'bazar'">
    <div class="col-md-2">
      COULEUR :
    </div>
    <div class="col-md-3">
      <strong>{{ sysDeclination.declination.value }}</strong>
    </div>
  </div>
  <div class="row mb10">
    <div class="col-md-12">
      <div class="taille" [class.issue]="stockIssue" *ngFor="let variant of sysDeclination.variants">
        {{ this.sharedVariablesService.saleChannel.type === 'bazar' ? 'QTÉ' : variant.productVariant?.value }} : <strong class="accent">{{ variant.quantity }}</strong>
      </div>
    </div>
  </div>
  <div class="key-infos">
    <div class="row">
      <div class="col-md-3 totals" *ngIf="!sharedVariablesService.saleChannel.isWishlist()">
        <div>TOTAL COLIS : <strong class="accent">{{ sysDeclination.declination.totalSalesUnitCount / sysDeclination.declination.salesUnitCount }}</strong></div>
        <div>TOTAL UVC : <strong>{{ sysDeclination.declination.totalSalesUnitCount }}</strong></div>
      </div>
      <div class="col-md-3 text-center" [ngClass]="{ 'col-md-4': sharedVariablesService.saleChannel.isWishlist() }">CA Cession HT <div class="value" [innerHTML]="sysDeclination.declination.totalPurchasingAmount | price"></div></div>
      <div class="col-md-3 text-center" [ngClass]="{ 'col-md-4': sharedVariablesService.saleChannel.isWishlist() }">CA Vente TTC <div class="value" [innerHTML]="sysDeclination.declination.totalAmount | price"></div></div>
      <div class="col-md-3 price text-center" [ngClass]="{ 'col-md-4': sharedVariablesService.saleChannel.isWishlist() }">Tx marge brute <br><strong>{{ sysDeclination.declination.marginRate | percent }}</strong></div>
    </div>
    <div class="row mt10" *ngIf="editable">
      <div class="col-md-6">
        <button mat-raised-button color="primary" class="small" (click)="editProduct(sysDeclination.declination.productId, sysDeclination.declination.id)">MODIFIER</button>
      </div>
      <div class="col-md-6 text-right">
        <button mat-button color="warn" class="small" (click)="deleteFromCart(sysDeclination.variants)"><mat-icon aria-hidden="false" aria-label="Supprimer" class="font-size">delete</mat-icon> SUPPRIMER</button>
      </div>
    </div>
  </div>
  <ngx-ui-loader [loaderId]="'cart_' + sysDeclination.declination.id"></ngx-ui-loader>
</div>
