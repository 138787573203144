import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Advertisement {
  id: string;
  name: string;
  saleChannelCode: string;
  saleChannelCodeName: string;
  slot: string;
  slotName: string;
  redirectionUrl: string;
  uploadedFile: boolean;
  mediaUrl: string;
  userId: string;
  canDelete: boolean = false;

  constructor(id: string,
              name: string,
              saleChannelCode: string,
              saleChannelCodeName: string,
              slot: string,
              slotName: string,
              redirectionUrl: string,
              uploadedFile: boolean = false,
              mediaUrl: string,
              userId: string,
              canDelete: boolean) {
    this.id = id;
    this.name = name;
    this.saleChannelCode = saleChannelCode;
    this.slot = slot;
    this.redirectionUrl = redirectionUrl;
    this.uploadedFile = uploadedFile;
    this.mediaUrl = mediaUrl;
    this.userId = userId;
    this.canDelete = canDelete;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdvertisementAdapter implements Adapter<Advertisement> {
  adapt(item: any): Advertisement {
    return new Advertisement(
      item.id,
      item.name,
      item.saleChannelCode,
      item.saleChannelCodeName,
      item.slot,
      item.slotName,
      item.redirectionUrl,
      item.uploadedFile,
      item.mediaUrl,
      item.userId,
      item.canDelete
    );
  }

  prepare(object: Advertisement): any {
    return object;
  }
}
