<div class="product-small" [class.withTitle]="!forPlanogram">
  <div *ngIf="product">
    <div>
      <div class="product-image link" routerLink="/classeur/fiche-produit/{{ product.id }}/{{ product.selectedDeclination.id }}">
        <div class="text-right">
          <img *ngIf="product.brand?.urlImage" src="{{ product.brand?.urlImage }}" alt="{{ product.brand.name }}" class="brand">
        </div>
        <img class="link declinationImage" *ngIf="product.selectedDeclination.images && product.selectedDeclination.images.length > 0" src="{{ product.selectedDeclination.images[0].url }}" alt="{{ product.name }}" routerLink="/classeur/fiche-produit/{{ product.id }}/{{ product.selectedDeclination.id }}">
      </div>
      <div class="product-tags" *ngIf="product.tags">
        <div class="tag" *ngIf="product.hasTag('renewable')">Rec</div>
        <div class="tag" *ngIf="product.hasTag('innovation')"><mat-icon aria-hidden="false" title="Innovation" aria-label="Innovation" class="font-size">lightbulb_outline</mat-icon></div>
        <div class="tag" *ngIf="product.hasTag('new')">New</div>
        <div class="tag must-have" *ngIf="product.hasTag('must_have')"><mat-icon aria-hidden="false" title="Incontournable" aria-label="Must have" class="font-size">star</mat-icon></div>
        <div class="tag custom" *ngIf="product.hasTag('press_kit')"><img alt="Dossier de presse" title="Dossier de presse" src="/assets/images/dossier_de_presse.png"/></div>
        <div class="tag custom" *ngIf="product.hasTag('essential')"><img alt="Essentiel" title="Essentiel" src="/assets/images/essentiel.svg"/></div>
<!--        <div class="tag custom" *ngIf="product.hasTag('CLV')"><img alt="CLV" title="Communication Lieu de Vente" src="/assets/images/clv.svg"></div>-->
        <div class="tag custom" *ngIf="product.hasTag('CLV')"><img alt="TOTEM" title="TOTEM" src="/assets/images/totem.png"></div>
        <div class="tag custom" *ngIf="product.hasTag('comfy')"><img alt="Confortable" title="Confortable" src="/assets/images/comfy.png"></div>
        <div class="tag custom" *ngIf="product.hasTag('ultra_soft')"><img alt="Ultra doux" title="Ultra doux" src="/assets/images/ultra_soft.png"></div>
        <div class="tag custom" *ngIf="product.hasTag('blogger')"><img alt="Bloggeuse" title="Bloggeuse" src="/assets/images/bloggeuse.png"></div>
        <div class="tag custom" *ngIf="product.hasTag('heart_stroke')"><img alt="Coup de coeur" title="Coup de coeur" src="/assets/images/coup-de-coeur.jpg"></div>
      </div>
    </div>
    <h3 *ngIf="!forPlanogram" class="link" routerLink="/classeur/fiche-produit/{{ product.id }}/{{ product.selectedDeclination.id }}">{{ product.name }}</h3>
    <div class="mb10" *ngIf="!sharedVariablesService.saleChannel.isWishlist()">N° {{ product.selectedDeclination.reference }}</div>
    <div class="mb10" *ngIf="sharedVariablesService.saleChannel.isWishlist()">N° de présentation {{ product.selectedDeclination.productVariants[0]?.sku }}</div>


    <div class="row infos no-margin mb10">
      <div class="col-md-5 price text-center no-gutters">Px Cession HT <div class="value" [innerHtml]="product.selectedDeclination.price.purchasingPrice | price"></div></div>
      <div class="col-md-3 price text-center no-gutters">PV TTC <div class="value" [innerHtml]="product.selectedDeclination.price.normalPrice | price"></div></div>
      <div class="col-md-4 light text-center no-gutters">Tx marge brute <br>{{ product.selectedDeclination.price.marginRate | percent:'1.0-2'}}</div>
    </div>

    <div *ngIf="forPlanogram" class="float-right mt10">
      <button mat-raised-button [color]="alreadyInTransaction ? 'primary' : 'accent'" (click)="editProduct(product.id, product.selectedDeclination.id)">
        <mat-icon *ngIf="transactionCanBeUpdated && !alreadyInTransaction" aria-hidden="false" aria-label="Panier">add_shopping_cart</mat-icon>
        <mat-icon *ngIf="transactionCanBeUpdated && alreadyInTransaction" aria-hidden="false" aria-label="Panier">shopping_cart</mat-icon>
        <mat-icon *ngIf="!transactionCanBeUpdated" aria-hidden="false" aria-label="Voir">remove_red_eye</mat-icon>
      </button>
    </div>
    <div>Cdt : <strong>{{ product.salesUnitCount }}</strong></div>
    <ul>
      <li *ngIf="product.rangeCodes">À partir de {{ product.rangeCodes[0] }}</li>
      <li>{{ product.selectedDeclination.properties?.size_curves }}</li>
    </ul>
  </div>
  <ngx-ui-loader [loaderId]="'loader-product-' + (forPlanogram ? '' : pId + '-' + declinationId)"></ngx-ui-loader>
</div>
