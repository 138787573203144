import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Product} from '../../lib/model/Product';
import { ProductService } from '../../lib/services/product.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { ProductItemComponent } from '../product-item/product-item.component';

@Component({
  selector: 'app-product-item-small',
  templateUrl: './product-item-small.component.html',
  styleUrls: ['./product-item-small.component.scss'],
})
export class ProductItemSmallComponent implements OnInit {
  @Input() product: Product;
  @Input() declinationId: string;
  @Input() forPlanogram: boolean = false;
  @Output() closePopover: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set productId(value: string) {
    this.pId = value;
    this.loadProductFromId(this.pId);
  }
  public pId: string;
  public loading: boolean = false;
  public alreadyInTransaction: boolean = false;
  public transactionCanBeUpdated: boolean = true;

  constructor(public sharedVariablesService: SharedVariablesService,
              private dialog: MatDialog,
              private ngxLoaderService: NgxUiLoaderService,
              private productService: ProductService) { }

  ngOnInit() {
    this.transactionCanBeUpdated = this.sharedVariablesService.transaction.currentStatus.code === 'CREATED' && this.sharedVariablesService.saleChannel.isOpen(true);
  }

  private loadProductFromId(productId: string) {
    if (productId) {
      // Info : loader does not work in planogram if id of ngx-loader is changed in same (reused) component instance,
      // so we keep loader id constant in that case
      const loaderId = this.forPlanogram ? '' : productId + '-' + this.declinationId;
      this.product = undefined;
      const self = this;

      this.ngxLoaderService.startLoader('loader-product-' + loaderId);
      this.productService.getProduct(productId).toPromise().then((product) => {
        // If declination ID provided, select it
        if (self.declinationId) {
          product.selectDeclination(self.declinationId);
          const eans: string[] = [];
          product.selectedDeclination.productVariants.forEach((variant) => {
            eans.push(variant.ean);
          });
          self.alreadyInTransaction = self.sharedVariablesService.transaction.containsDeclinationEan(product.id, eans);
        }
        self.product = product;
        this.ngxLoaderService.stopLoader('loader-product-' + loaderId);
      });
    }
  }

  /**
   * Opens a product view for the user to change its choices.
   */
  editProduct(productId: string, declinationId: string) {
    this.closePopover.emit(true);
    this.dialog.open(ProductItemComponent, {
      width: '730px',
      data: { popin: true, fromCart: false, fromPlanogram: this.forPlanogram, productId, declinationId },
    });
  }
}
