<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="fondDeRayon ? 'Ma sélection' : 'Mon panier'"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2><ng-container *ngIf="sharedVariablesService.transaction?.properties.categoryName">{{ sharedVariablesService.transaction?.properties.categoryName }} <ng-container *ngIf="sharedVariablesService.transaction?.properties.rangeName">- </ng-container></ng-container>
        <ng-container *ngIf="sharedVariablesService.transaction?.properties.rangeName">{{ sharedVariablesService.transaction?.properties.rangeName }}</ng-container>
        <ng-container *ngIf="sharedVariablesService.saleChannel.type === 'permanent'">- {{ sharedVariablesService.transaction?.customer.priceScaleCode }}</ng-container></h2>
    </div>
    <div class="flex">
      <h2><span class="lighter">Du</span> {{ sharedVariablesService.saleChannel.openingDate | date : 'shortDate' }}
        <span class="lighter">au</span> {{ sharedVariablesService.saleChannel.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le classeur" class="link" [routerLink]="['/tableau-de-bord', sharedVariablesService.saleChannel.code]"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<app-menu></app-menu>

<div class="row section" *ngIf="systemeUTransaction">
  <div class="stocks-warning" *ngIf="sharedVariablesService.saleChannel.type === 'capsule' && systemeUTransaction.transaction.currentStatus.code !== 'VALIDATED'">
    <mat-icon class="font-size">warning</mat-icon> La disponibilité d'un produit <strong>n'est pas garantie avant la clôture et transmission de votre commande.</strong>
  </div>
  <div class="stocks-alert" *ngIf="itemsWithInsufficientStocks.length > 0">
    <p><mat-icon color="white" class="font-size">warning</mat-icon> Les stocks sont insuffisants pour les produits suivants :</p>
    <ul>
      <li *ngFor="let itemsWithInsufficientStock of itemsWithInsufficientStocks" (click)="editProduct(itemsWithInsufficientStock.declination.productId, itemsWithInsufficientStock.declination.id)">{{itemsWithInsufficientStock.declination.name}} ({{ itemsWithInsufficientStock.declination.reference }})</li>
    </ul>
  </div>
  <div class="col-md-8">
    <app-cart-product *ngFor="let declination of (sortBy ? (systemeUTransaction.declinations | sortbynumphoto: sortBy) : (systemeUTransaction.declinations))"
                      [editable]="canEditTransaction" [sysDeclination]="declination"
                      (transactionUpdated)="updateTransaction($event)"
                      [stockIssue]="isStockInsufficient(declination.declination.id)" ></app-cart-product>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <mat-select placeholder="Trier par" [(value)]="sortBy">
        <mat-option value="asc">Num. photo ascendant</mat-option>
        <mat-option value="desc">Num. photo descendant</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="summary">
      <h3 class="text-center" *ngIf="!fondDeRayon">PANIER</h3>
      <h3 class="text-center" *ngIf="fondDeRayon">SÉLECTION</h3>
      <p class="text-center" *ngIf="!fondDeRayon">{{ systemeUTransaction.declinations.length }} lignes de commande</p>
      <p class="text-center" *ngIf="fondDeRayon">{{ systemeUTransaction.declinations.length }} lignes</p>
      <div class="flex accent medium" *ngIf="!fondDeRayon">
        <div>Qté UVC</div>
        <strong>{{ systemeUTransaction.transaction.totalSalesUnitCount }}</strong>
      </div>
      <div class="flex accent medium">
        <div>CA Cession HT</div>
        <strong>{{ systemeUTransaction.transaction.totalPurchasingAmount | currency: 'EUR'}}</strong>
      </div>
      <div class="flex accent medium">
        <div>CA Vente TTC</div>
        <strong>{{ systemeUTransaction.transaction.totalAmount | currency: 'EUR'}}</strong>
      </div>
      <div class="flex">
        <div>Tx Marge Brute</div>
        <strong>{{ systemeUTransaction.transaction.totalMarginRate | percent }}</strong>
      </div>
      <div class="text-center mt20 mb10" *ngIf="systemeUTransaction.declinations.length > 0">
        <a mat-button (click)="finalize()" class="small" href="/api/systemeu/transactions/pdf?transactionIds={{ systemeUTransaction.transaction.id }}&token={{ authenticationService.getCurrentUser().token }}" target="_blank">
          <mat-icon aria-hidden="false" aria-label="Imprimer" class="font-size">print</mat-icon> Imprimer le récapitulatif
        </a>
      </div>
      <button *ngIf="!fondDeRayon &&  canEditTransaction && systemeUTransaction.declinations.length > 0" mat-raised-button color="primary" class="full-width" (click)="validateTransaction()" [disabled]="systemeUTransaction.itemsWithInsufficientStocks.length > 0">VALIDER MON CLASSEUR</button>
      <p *ngIf="systemeUTransaction.transaction.currentStatus.code === 'TO_VALIDATE' || systemeUTransaction.transaction.currentStatus.code === 'VALIDATED'" class="text-center success"><mat-icon aria-hidden="false" aria-label="Validé" class="font-size mr5">check_circle</mat-icon> <strong>Le classeur est validé.</strong></p>
    </div>
  </div>
</div>
