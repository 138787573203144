import { Adapter } from '../interfaces/adapter.interface';

export class Organisation {
  id: string;
  name: string;
  type: string;

  constructor(id: string, name: string, type: string) {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}

export class OrganisationAdapter implements Adapter<Organisation> {
  adapt(item: any): Organisation {
    return new Organisation(item.id,
                            item.name,
                            item.type,
    );
  }
}
