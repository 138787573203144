import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ProductVariant, ProductVariantAdapter } from './ProductVariant';
import { Transaction } from './Transaction';

export class Item {
  ean: string;
  name: string;
  description: string;
  quantity: number;
  salesUnitCount: number;
  unitPrice: number;
  unitGrossPrice: number;
  totalGrossAmount: number;
  totalAmount: number;
  type: string;
  productId: string;
  productVariant: ProductVariant;

  constructor(ean: string, name: string, description: string, quantity: number, salesUnitCount: number, unitPrice: number, unitGrossPrice: number, totalGrossAmount: number, totalAmount: number, type: string, productId: string, productVariant: ProductVariant) {
    this.ean = ean;
    this.name = name;
    this.description = description;
    this.quantity = quantity;
    this.salesUnitCount = salesUnitCount;
    this.unitPrice = unitPrice;
    this.unitGrossPrice = unitGrossPrice;
    this.totalGrossAmount = totalGrossAmount;
    this.totalAmount = totalAmount;
    this.type = type;
    this.productId = productId;
    this.productVariant = productVariant;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ItemAdapter implements Adapter<Item> {
  adapt(item: any): Item {
    const productVariantAdapter = new ProductVariantAdapter();

    return new Item(
      item.ean,
      item.name,
      item.description,
      item.quantity,
      item.salesUnitCount,
      item.unitPrice,
      item.unitGrossPrice,
      item.totalGrossAmount,
      item.totalAmount,
      item.type,
      item.productId,
      productVariantAdapter.adapt(item.productVariant)
    );
  }

  prepare(object: Item): any {
    const target = {};

    Object.assign(target, object);
    delete target['productVariant'];

    return target;
  }
}
