<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="'Éditorial du salon'"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" title="Retour" routerLink="/salons"><mat-icon>arrow_back</mat-icon></button>
      <h2><span class="lighter">Bienvenue sur le </span>{{ saleChannel?.name }}</h2>
    </div>
    <div class="flex">
      <h2 *ngIf="saleChannel?.isWishlist()"><span class="lighter">Fin de sélection de la collection le</span> {{ saleChannel?.closingDate | date : 'shortDate' }}</h2>
      <h2 *ngIf="!saleChannel?.isWishlist()"><span class="lighter">Fin de la transmission des commandes le</span> {{ saleChannel?.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le classeur" title="Quitter le classeur" (click)="googleAnalyticsService.eventEmitter('click_exit_exhibition')" routerLink="/salons"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<div class="section pt30 pb20">
  <div class="text-right mb30">
    <button mat-raised-button color="accent" (click)="goToDashboard()">ACCÉDER AU SALON</button>
  </div>
  <div class="advertisements" *ngIf="advertisementsOperation.length > 0">
    <h2>Informations générales</h2>
    <div class="text-left">
      <a class="advertisement" *ngFor="let advertisement of advertisementsOperation" href="{{ buildRedirectionUrl(advertisement.redirectionUrl) }}" target="_blank"
      (click)="clickMedia(advertisement)">
        <img src="{{ advertisement.mediaUrl }}" alt="{{ advertisement.name }}">
        <div class="click" *ngIf="advertisement.redirectionUrl && isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour télécharger</div>
        <div class="click" *ngIf="advertisement.redirectionUrl && !isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour accéder</div>
      </a>
    </div>
  </div>
  <div class="advertisements" *ngIf="advertisementsCommandTool.length > 0">
    <h2>Collections Fond de rayon</h2>
    <div class="text-left">
      <a class="advertisement" *ngFor="let advertisement of advertisementsCommandTool" href="{{ buildRedirectionUrl(advertisement.redirectionUrl) }}" target="_blank"
         (click)="clickMedia(advertisement)">
        <img src="{{ advertisement.mediaUrl }}" alt="{{ advertisement.name }}">
        <div class="click" *ngIf="advertisement.redirectionUrl && isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour télécharger</div>
        <div class="click" *ngIf="advertisement.redirectionUrl && !isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour accéder</div>
      </a>
    </div>
  </div>
  <div class="advertisements" *ngIf="advertisementsCollectionPresentation.length > 0">
    <h2>Collections Animations Commerciales</h2>
    <div class="text-left">
      <a class="advertisement" *ngFor="let advertisement of advertisementsCollectionPresentation" href="{{ buildRedirectionUrl(advertisement.redirectionUrl) }}" target="_blank"
         (click)="clickMedia(advertisement)">
        <img src="{{ advertisement.mediaUrl }}" alt="{{ advertisement.name }}">
        <div class="click" *ngIf="advertisement.redirectionUrl && isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour télécharger</div>
        <div class="click" *ngIf="advertisement.redirectionUrl && !isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour accéder</div>
      </a>
    </div>
  </div>
  <div class="advertisements" *ngIf="advertisements.length > 0">
    <h2>Divers</h2>
    <div class="text-left">
      <a class="advertisement" *ngFor="let advertisement of advertisements" href="{{ buildRedirectionUrl(advertisement.redirectionUrl) }}" target="_blank"
         (click)="clickMedia(advertisement)">
        <img src="{{ advertisement.mediaUrl }}" alt="{{ advertisement.name }}">
        <div class="click" *ngIf="advertisement.redirectionUrl && isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour télécharger</div>
        <div class="click" *ngIf="advertisement.redirectionUrl && !isKeyneosoftHosted(advertisement.redirectionUrl)">Cliquez pour accéder</div>
      </a>
    </div>
  </div>
  <div class="text-right mt30">
    <button mat-raised-button color="accent" (click)="goToDashboard()">ACCÉDER AU SALON</button>
  </div>
  <ngx-ui-loader [loaderId]="'loader-editorial'"></ngx-ui-loader>
</div>
