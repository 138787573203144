import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticSearchResult, ElasticSearchResultAdapter } from '../model/ElasticSearchResult';
import { Product, ProductAdapter } from '../model/Product';
import { ProductPage, ProductPageAdapter } from '../model/ProductPage';
import { SharedVariablesService } from './shared-variables.service';
import { Planogram, PlanogramAdapter } from '../model/Planogram';

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  constructor(private http: HttpClient,
              private adapter: ProductAdapter,
              private elasticAdapter: ElasticSearchResultAdapter,
              private sharedVariablesService: SharedVariablesService,
              private productPageAdapter: ProductPageAdapter,
              private planogramAdapter: PlanogramAdapter) {
  }

  /**
   * Get a product by its ean / product id.
   * @param ean
   */
  public getProduct(ean: string): Observable<Product> {
    const headers = new HttpHeaders({ organisationId: 'SYSTEME_U'});
    let params = new HttpParams();
    params = params.set('priceScaleCode', this.sharedVariablesService.transaction.customer.priceScaleCode)
                   .set('addStock', 'true');

    return this.http.get<Product>('/api/products/' + ean, {params, headers}).pipe(map(item => this.adapter.adapt(item)));
  }

  /**
   * Get products for current organisation.
   * @param categoryCode Code of the category to search in.
   * @param from Index of product to return from.
   * @param size Size of result (number of products).
   * @deprecated Retrieve product by category / brand / planogram via Elastic (next method). Method here for testing purpose only.
   */
  public getProducts(categoryCode: string, from: number, size: number = 5): Observable<ProductPage> {
    let params = new HttpParams();
    params = params.set('size', size.toString())
                   .set('page', Math.floor(from / size).toString())
                   .set('categories', categoryCode)
                   .set('priceScaleCode', this.sharedVariablesService.transaction.customer.priceScaleCode);

    return this.http.get<ProductPage>('/api/products', {params}).pipe(map(item => this.productPageAdapter.adapt(item)));
  }

  /**
   * Get products from Elastic Search.
   * @param categoryCode
   * @param saleChannelCode
   * @param from Index of product to return from.
   * @param size Size of result (number of products).
   * @param brands
   * @param sizes
   * @param catalog
   * @param theme
   * @param categories
   * @param tags
   * @param term
   * @param rangeRank
   * @param rangeName
   * @param rangeRecommendation
   */
  public searchProducts(categoryCode: string,
                        saleChannelCode: string,
                        from: number = 0,
                        size: number = 5,
                        brands?: string[],
                        sizes?: string[],
                        catalog?: string[],
                        theme?: string[],
                        categories?: string[],
                        tags?: string[],
                        term?: string,
                        rangeRank?: number,
                        rangeName?: string[],
                        rangeRecommendation?: string[],
                        sortingField: string = 'reference'): Observable<ElasticSearchResult> {
    const filters = {};
    if (brands) {
      filters['brand'] = brands;
    }
    if (categoryCode || categories) {
      filters['category'] = (categories && categories.length > 0) ? categories : categoryCode;
    }
    if (sizes) {
      filters['SIZE'] = sizes;
    }
    if (catalog) {
      filters['catalog_label'] = catalog;
    }
    if (theme) {
      filters['theme_label'] = theme;
    }
    if (tags) {
      filters['tags'] = tags;
    }
    if (rangeRank) {
      filters['rangeRank'] = '0;' + rangeRank;
    }
    if (rangeName) {
      filters['range_name'] = rangeName;
    }
    if (rangeRecommendation) {
      filters['range_recommendation'] = rangeRecommendation;
    }

    let sort: any;
    if (term && /^\d+$/g.test(term)) {
      sort = undefined;
    } else {
      sort = {field: sortingField, order: 'asc'};
    }

    return this.http.post<Product[]>('/api/search/product', {
      saleChannelCode,
      priceScaleCode: this.sharedVariablesService.transaction.customer.priceScaleCode,
      term,
      items: {
        from,
        size,
        sort,
      },
      filters}).pipe(map(item => this.elasticAdapter.adapt(item)));
  }

  /**
   * Get planogram data.
   * @param categoryCode
   * @param categoryRange
   */
  public getPlanogram(categoryCode: string, categoryRange: string): Observable<Planogram> {
    return this.http.get<Planogram>('/api/systemeu/planogram/' + categoryCode + '/' + encodeURIComponent(categoryRange))
      .pipe(map(item => this.planogramAdapter.adapt(item)));
  }
}
