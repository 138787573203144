<div class="menu">
  <div class="section">
    <ul>
      <li *ngIf="this.sharedVariablesService.saleChannel.type === 'capsule'"><span class="link" [routerLink]="['/classeur', sharedVariablesService.transaction.id]">Capsule</span></li>
      <li *ngIf="this.sharedVariablesService.saleChannel.type === 'FOND_DE_RAYON'"><span class="link" [routerLink]="['/classeur', sharedVariablesService.transaction.id]">Fond de rayon</span></li>
      <li *ngIf="this.sharedVariablesService.saleChannel.type === 'permanent'"><span class="link" [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'planogramme']">Dossier merchandising</span></li>
      <li *ngIf="category"><span class="link" [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'categorie', sharedVariablesService.transaction.properties.categoryCode]">{{ sharedVariablesService.saleChannel.type === 'bazar' ? 'Catégorie' : 'Assortiment' }}</span>
        <div class="columns-2" *ngIf="category.children.length > 0">
          <div class="group" *ngFor="let cat of category.children">
            <div class="category top"><span [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'categorie', cat.code]">{{ cat.name }}</span></div>
            <div class="category" *ngFor="let subcat of cat.children"><span [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'categorie', subcat.code]">{{ subcat.name }}</span></div>
          </div>
        </div>
      </li>
      <li *ngIf="brands.length > 0 && this.sharedVariablesService.saleChannel.type === 'permanent'">Marque
        <div class="columns-3">
          <div class="brand" *ngFor="let brand of brands" [routerLink]="['/classeur', sharedVariablesService.transaction.id, 'marque', brand.key]">{{ brand.value }}</div>
        </div>
      </li>
    </ul>
  </div>
</div>
