import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Product } from '../../lib/model/Product';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { ProductService } from '../../lib/services/product.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  public product: Product;

  constructor(private route: ActivatedRoute,
              private location: Location,
              private productService: ProductService,
              public sharedVariablesService: SharedVariablesService,
              public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    const ean = this.route.snapshot.paramMap.get('ean');
    const declinationId = this.route.snapshot.paramMap.has('declinationid') ? this.route.snapshot.paramMap.get('declinationid') : undefined;

    this.productService.getProduct(ean).pipe(take(1)).subscribe((item) => {
      if (declinationId) {
        item.selectDeclination(declinationId);
      }
      this.product = item;

      this.googleAnalyticsService.viewItem(this.product);
    });
  }

  goBack() {
    this.location.back();
  }
}
