import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export interface CategoryRange {
  code: string;
  name: string;
  rank: string;
}

export class Category {
  code: string;
  name: string;
  parentCategoryCode: string;
  highlight: false;
  ranges: CategoryRange[];
  children: Category[] = [];

  constructor(code: string, name: string, parentCategoryCode: string, highlight: false, ranges: CategoryRange[], children: Category[] = []) {
    this.code = code;
    this.name = name;
    this.parentCategoryCode = parentCategoryCode;
    this.highlight = highlight;
    this.ranges = ranges;
    this.children = children;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CategoryAdapter implements Adapter<Category> {
  adapt(item: any): Category {
    return new Category(
      item.code,
      item.name,
      item.parentCategoryCode,
      item.highlight,
      item.ranges,
      item.children ? item.children.map((elt) => this.adapt(elt)) : []
    );
  }
}
