import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add authorization header with auth credentials if available
    const currentUser = this.authenticationService.getCurrentUser();

    if (currentUser && currentUser.token && currentUser.organisation) {
      let organisationId: string;
      // We will keep organisationId if defined in request
      if (request.headers.has('organisationId')) {
        organisationId = request.headers.get('organisationId');
      }

      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + currentUser.token,
          organisationId: organisationId ? organisationId : currentUser.organisation.id,
        },
      });
    }

    return next.handle(request);
  }
}
