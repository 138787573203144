<div class="filter app-filter-single-choice-container full-width">
  <mat-label class="mat-label force-line-break" (click)="toggleVisibility()">{{ filter.name }}
    <mat-icon class="link" *ngIf="!filter.visible">keyboard_arrow_right</mat-icon>
    <mat-icon class="link" *ngIf="filter.visible">keyboard_arrow_down</mat-icon>
  </mat-label>
  <div class="filter-content" [ngClass]="!filter.visible ? 'collapsed' : ''">
    <mat-radio-button class="full-width no-wrap-truncated" *ngFor="let filterOption of filter.options"
                       [checked]="filter.value === filterOption.id"
                      (change)="updateSelected(filterOption.id, $event)">{{ filterOption.name }}
    </mat-radio-button>
  </div>
</div>
