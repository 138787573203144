import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Category } from '../../lib/model/Category';
import { Product } from '../../lib/model/Product';
import { CategoryService } from '../../lib/services/category.service';
import { FilterService } from '../../lib/services/filter.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { ProductService } from '../../lib/services/product.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import {
  Filter,
  FilterMultipleChoice,
  FilterMultipleChoiceTags,
  FilterMultipleChoiceTree,
  FilterOption, FilterSingleChoice,
  FilterSingleChoiceTree
} from '../../lib/model/Filter';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent implements OnInit, OnDestroy {
  @ViewChild('filterPanel', {static: true}) filterPanelElement: ElementRef;
  public filters: Filter[] = [];
  public showGoUpArrow: boolean = false;
  public category: Category;
  public products: Product[] = [];
  public sticky: boolean = false;
  public filtersTopPosition: number;
  public allProductsLoaded: boolean = false;
  public disableScroll: boolean = false;
  public total: number = 0;
  public searchTerm: string;
  public brand: string = undefined;
  public sortingFields: any[] = [{ code: 'reference', name: 'N° présentation'}, { code: 'rank', name: 'N° semaine'}];
  public sortingField: string = 'reference';
  private rootCategoryCode: string;
  private queryParamsSubscription: Subscription;
  private paramsSubscription: Subscription;
  private brandsFilter: FilterMultipleChoice;
  private sizesFilter: FilterMultipleChoiceTags;
  private categoriesFilter: FilterMultipleChoiceTree;
  private tagsFilter: FilterMultipleChoice;
  private catalogFilter: FilterMultipleChoice;
  private themeFilter: FilterMultipleChoice;
  private merchandisingFilter: FilterSingleChoice;
  private rangeFilter: FilterMultipleChoice;
  private rangeRecommendationFilter: FilterMultipleChoice;

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private filterService: FilterService,
              private location: Location,
              private ngxLoaderService: NgxUiLoaderService,
              private productService: ProductService,
              private categoryService: CategoryService,
              private transactionService: TransactionService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    // Search term if passed in GET parameters
    this.searchTerm = this.route.snapshot.queryParamMap.get('term');
    // Subscribe to changes for term GET parameter
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      if (this.searchTerm !== params.get('term')) {
        this.searchTerm = params.get('term');
        this.loadProducts(true);
      }
    });

    // =======================================
    // Create filters (empty, then fill preselected values from URL, then load options via Elastic)
    // =======================================
    this.brandsFilter = new FilterMultipleChoice('brands', 'Marque', [], [], false);
    // If brand filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('brands')) {
      this.brandsFilter.selected = this.route.snapshot.queryParamMap.get('brands').split(',');
      this.brandsFilter.visible = true;
      this.filterService.filterSubject.next(this.brandsFilter);
    }

    this.sizesFilter = new FilterMultipleChoiceTags('sizes', 'Taille', [], [], false);
    // If size filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('sizes')) {
      this.sizesFilter.selected = this.route.snapshot.queryParamMap.get('sizes').split(',');
      this.sizesFilter.visible = true;
      this.filterService.filterSubject.next(this.sizesFilter);
    }

    this.catalogFilter = new FilterMultipleChoice('catalog', 'Catalogue', [], [], false);
    // If size filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('catalog')) {
      this.catalogFilter.selected = this.route.snapshot.queryParamMap.get('catalog').split(',');
      this.catalogFilter.visible = true;
      this.filterService.filterSubject.next(this.catalogFilter);
    }

    this.themeFilter = new FilterMultipleChoice('theme', 'Thème', [], [], false);
    // If size filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('theme')) {
      this.themeFilter.selected = this.route.snapshot.queryParamMap.get('theme').split(',');
      this.themeFilter.visible = true;
      this.filterService.filterSubject.next(this.themeFilter);
    }

    this.categoriesFilter = new FilterMultipleChoiceTree('categories', 'Catégorie', [], [], false);

    this.tagsFilter = new FilterMultipleChoice('tags', 'Mis en avant', [], [], false);
    if (['permanent'].includes(this.sharedVariablesService.saleChannel.type)) {
      this.tagsFilter.options.push(
        new FilterOption('must_have', 'Incontournable'),
        new FilterOption('new', 'Nouveauté'),
        new FilterOption('renewable', 'Reconduit'));
    }
    if (['FOND_DE_RAYON'].includes(this.sharedVariablesService.saleChannel.type)) {
      this.tagsFilter.options.push(
        new FilterOption('blogger', 'Bloggueuse'),
        // new FilterOption('CLV', 'Com. Lieu de Vente'),
        new FilterOption('heart_stroke', 'Coup de coeur'),
        new FilterOption('comfy', 'Confortable'),
        new FilterOption('ultra_soft', 'Ultra doux'),
        new FilterOption('press_kit', 'Dossier de presse'),
        new FilterOption('essential', 'Essentiel'),
        new FilterOption('dressing', 'Dressing'),
        new FilterOption('big_size', 'Existe en grande taille'));
    }
    if (['bazar'].includes(this.sharedVariablesService.saleChannel.type)) {
      this.tagsFilter.options.push(
        new FilterOption('heart_stroke', 'Coup de coeur'),
        new FilterOption('must_have', 'Incontournable'),
        new FilterOption('new', 'Nouveauté'));
    }
    // If tags filter set in GET params, set selected values and trigger refresh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('tags')) {
      this.tagsFilter.selected = this.route.snapshot.queryParamMap.get('tags').split(',');
      this.tagsFilter.visible = true;
      this.filterService.filterSubject.next(this.tagsFilter);
    }

    this.merchandisingFilter = new FilterSingleChoice('merchandising', 'Dossier merchandising', [
      new FilterOption(undefined, 'Tous les produits'),
      new FilterOption('inMerchandising', 'Présents dans le dossier merchandising'),
      new FilterOption('notInMerchandising', 'Absents du dossier merchandising'),
    ], undefined, false);
    // If merchandising filter set in GET params, set selected values and trigger refresh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('merchandising')) {
      this.merchandisingFilter.value = this.route.snapshot.queryParamMap.get('merchandising').toString();
      this.merchandisingFilter.visible = true;
      this.filterService.filterSubject.next(this.merchandisingFilter);
    }

    this.rangeFilter = new FilterMultipleChoice('range', 'Niveau tract', [
      new FilterOption('HU2', 'HU2'),
      new FilterOption('HU2/HU1', 'HU2/HU1'),
      new FilterOption('HU2 à SU5', 'HU2 à SU5'),
      new FilterOption('HU2 à SU4', 'HU2 à SU4'),
      new FilterOption('HU2 à SU3', 'HU2 à SU3'),
      new FilterOption('HU2 à SU2', 'HU2 à SU2'),
      new FilterOption('Offre complémentaire', 'Offre complémentaire'),
    ], [], false, 'Cochez votre positionnement + tous les positionnements au dessous du vôtre');
    // If range filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('range')) {
      this.rangeFilter.selected = this.route.snapshot.queryParamMap.get('range').split(',');
      this.rangeFilter.visible = true;
      this.filterService.filterSubject.next(this.rangeFilter);
    }

    this.rangeRecommendationFilter = new FilterMultipleChoice('range_recommendation', 'Gamme préco. / Vagues de livraison', [], [], false);
    // If brand filter set in GET params, set selected values and trigger refesh of filter panel via filter service
    if (this.route.snapshot.queryParamMap.get('range_recommendation')) {
      this.rangeRecommendationFilter.selected = this.route.snapshot.queryParamMap.get('range_recommendation').split(',');
      this.rangeRecommendationFilter.visible = true;
      this.filterService.filterSubject.next(this.rangeRecommendationFilter);
    }

    this.filters = [this.brandsFilter, this.sizesFilter, this.categoriesFilter, this.tagsFilter];

    if (this.sharedVariablesService.saleChannel.type === 'permanent') {
      this.filters.push(this.merchandisingFilter);
    }
    if (this.sharedVariablesService.saleChannel.isWishlist()) {
      this.filters.push(this.catalogFilter);
      this.filters.push(this.themeFilter);
    }
    if (this.sharedVariablesService.saleChannel.type === 'bazar') {
      this.filters.push(this.rangeRecommendationFilter);
      this.filters.push(this.rangeFilter);
    }

    // Subscribe to params changes
    const self = this;
    this.paramsSubscription = this.route.paramMap.subscribe((params) => {
      // Category code in params
      self.rootCategoryCode = params.has('categorycode') ? params.get('categorycode') : self.sharedVariablesService.transaction.properties.categoryCode;
      // Brand code in params
      self.brand = params.has('brand') ? params.get('brand') : undefined;
      self.initProductsAndFilters();
      // Preselect filters
      // If categories filter set in GET params, set selected values and trigger refesh of filter panel via filter service
      if (this.route.snapshot.queryParamMap.get('categories')) {
        this.categoriesFilter.selected = this.route.snapshot.queryParamMap.get('categories').split(',');
        this.categoriesFilter.visible = true;
        this.filterService.filterSubject.next(this.categoriesFilter);
      }
    });

    // Threshold for putting filter panel sticky
    this.filtersTopPosition = 217;
    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * Initialize filters (categories, brands and sizes) and load products.
   */
  private initProductsAndFilters() {
    const self = this;

    // If Permanent
    if (['permanent', 'bazar'].includes(this.sharedVariablesService.saleChannel.type)) {
      // Fetch categories and retrieve current (linked with sale channel)
      this.categoryService.getCategoriesTree(this.rootCategoryCode).pipe(take(1)).subscribe((category) => {
        self.category = category;

        // When category is found, feed the category filter with children
        if (self.category) {
          const options: FilterOption[] = [];
          self.category.children.sort((a, b) => a.name.localeCompare(b.name)).forEach((cat) => {
            const suboptions: FilterOption[] = [];
            cat.children.sort((a, b) => a.name.localeCompare(b.name)).forEach((subcat) => {
              suboptions.push(new FilterOption(subcat.code, subcat.name));
            });
            options.push(new FilterOption(cat.code, cat.name, suboptions));
          });
          // Load categories filter values
          self.categoriesFilter.options = options;
        }
      });
    } else {
      // Fetch categories
      this.categoryService.getCategoriesByRange(this.sharedVariablesService.transaction.saleChannelCode, this.sharedVariablesService.transaction.properties.rangeCode).toPromise().then((categories) => {
        // When categories  found, feed the category filter with children
        if (categories.length) {
          const options: FilterOption[] = [];
          categories.forEach((cat) => {
            const suboptions: FilterOption[] = [];
            cat.children.sort((a, b) => a.name.localeCompare(b.name)).forEach((subcat) => {
              suboptions.push(new FilterOption(subcat.code, subcat.name));
            });
            options.push(new FilterOption(cat.code, cat.name, suboptions));
          });
          // Load categories filter values
          self.categoriesFilter.options = options;
        }
      });
    }

    // Request full transaction, then load products
    this.sharedVariablesService.loadFullTransaction(this.route.snapshot.paramMap.get('transactionid')).then((transaction) => {
      this.rootCategoryCode = this.rootCategoryCode ? this.rootCategoryCode : transaction.properties.categoryCode;
      self.loadProducts(true);

      // Fetch sizes and brands from Elastic from current category
      self.productService.searchProducts( self.rootCategoryCode,
                                          self.sharedVariablesService.saleChannel.code,
                                          0,
                                          0,
                                          self.brand ? [self.brand] : [],
                                          [],
                                          [],
                                          [],
                                          [],
                                          undefined,
                                          this.searchTerm,
                                          self.sharedVariablesService.saleChannel.type === 'permanent' ? self.sharedVariablesService.transaction.properties.rangeRank : undefined,
                                          ['capsule', 'FOND_DE_RAYON'].includes(self.sharedVariablesService.saleChannel.type) ? [self.sharedVariablesService.transaction.properties.rangeName] : undefined)
        .toPromise().then((elasticSearchResult) => {
        self.sizesFilter.options = [];
        if (elasticSearchResult.filters.SIZE) {
          elasticSearchResult.filters.SIZE.buckets.forEach((bucket) => {
            self.sizesFilter.options.push(new FilterOption(bucket.key, bucket.key));
          });
        }
        self.brandsFilter.options = [];
        if (elasticSearchResult.filters.brand && elasticSearchResult.filters.brand.buckets.length > 1) {
          elasticSearchResult.filters.brand.buckets.sort((a, b) => a.value.localeCompare(b.value)).forEach((bucket) => {
            self.brandsFilter.options.push(new FilterOption(bucket.key, bucket.value));
          });
        }
        self.catalogFilter.options = [];
        if (elasticSearchResult.filters.catalog_label && elasticSearchResult.filters.catalog_label.buckets.length > 1) {
          elasticSearchResult.filters.catalog_label.buckets.sort((a, b) => a.key.localeCompare(b.key)).forEach((bucket) => {
            self.catalogFilter.options.push(new FilterOption(bucket.key, bucket.key));
          });
        }
        self.themeFilter.options = [];
        if (elasticSearchResult.filters.theme_label && elasticSearchResult.filters.theme_label.buckets.length > 1) {
          elasticSearchResult.filters.theme_label.buckets.forEach((bucket) => {
            self.themeFilter.options.push(new FilterOption(bucket.key, bucket.key));
          });
        }
        self.rangeRecommendationFilter.options = [];
        if (elasticSearchResult.filters.range_recommendation && elasticSearchResult.filters.range_recommendation.buckets.length > 1) {
          elasticSearchResult.filters.range_recommendation.buckets.forEach((bucket) => {
            self.rangeRecommendationFilter.options.push(new FilterOption(bucket.key, bucket.key));
          });
        }
      });
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll.bind(this), true);
    this.queryParamsSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  /**
   * As we use RouteReuseStrategy (via helpers/routing.ts class) for this component (to retrieve it from storage when navigating back from
   * a product details page), we must disable scrolling events when moving away from the page.
   */
  ngOnDetach() {
    this.disableScroll = true;
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * As we use RouteReuseStrategy (via helpers/routing.ts class) for this component (to retrieve it from storage when navigating back from
   * a product details page), we must re-enable scrolling events when moving back to this page.
   */
  ngOnAttach() {
    this.disableScroll = false;
    // Request full transaction
    this.sharedVariablesService.loadFullTransaction(this.route.snapshot.paramMap.get('transactionid')).then((transaction) => {
      this.loadProducts(true, true);
    });
    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * Triggers when a filter is updated.
   * @param $event
   */
  filterUpdated($event?: FilterSingleChoiceTree | FilterMultipleChoiceTree | FilterMultipleChoice) {
    // =======================================
    // 1. Update page URL with applied filters
    // =======================================
    let httpParams = new HttpParams();
    let path = this.location.path().split('?', 1)[0];
    let queryString: string = '';
    this.filters.forEach((filter) => {
      // @ts-ignore
      if (typeof(filter.selected) !== 'undefined' && filter.selected.length > 0) {
        // @ts-ignore
        queryString += filter.id + '=' + filter.selected.toString();
        // @ts-ignore
        httpParams = httpParams.set(filter.id, filter.selected.toString());
      }
      // @ts-ignore
      if (typeof(filter.value) !== 'undefined' && filter.value) {
        // @ts-ignore
        queryString += filter.id + '=' + filter.value;
        // @ts-ignore
        httpParams = httpParams.set(filter.id, filter.value);
      }
    });
    if (this.searchTerm) {
      httpParams = httpParams.set('term', this.searchTerm);
    } else {
      path = path.replace('recherche', '');
    }
    this.location.replaceState(path, httpParams.toString());

    // =======================================
    // 2. Search via elastic
    // =======================================
    this.loadProducts(true);

    // GA
    let filterValue: string;
    if (typeof($event.selected) !== 'undefined') {
      filterValue = $event.selected.join(',');
    } else {
      if (!($event instanceof FilterMultipleChoice)) {
        filterValue = $event.value;
      }
    }
    this.googleAnalyticsService.eventEmitter('click_filter', {
      filterName: $event.name,
      filterValue,
    });
  }

  /**
   * When product list is scrolled, load more products.
   */
  loadProducts(forceReload: boolean = false, fullLoad: boolean = false) {
    if ((!this.allProductsLoaded && !this.disableScroll) || forceReload) {
      // Fetch products
      const start: number = forceReload ? 0 : this.products.length;
      const size: number = fullLoad ? this.products.length : 10;
      this.ngxLoaderService.start();
      const self = this;

      this.productService.searchProducts( this.rootCategoryCode,
                                          self.sharedVariablesService.saleChannel.code,
                                          start,
                                          size,
                                          this.brand ? self.brandsFilter.selected.concat(this.brand) : self.brandsFilter.selected,
                                          self.sizesFilter.selected,
                                          self.catalogFilter.selected,
                                          self.themeFilter.selected,
                                          self.categoriesFilter.selected,
                                          self.merchandisingFilter.value ? self.tagsFilter.selected.concat(self.merchandisingFilter.value) : self.tagsFilter.selected,
                                          self.searchTerm,
                                          self.sharedVariablesService.saleChannel.type === 'permanent' ? self.sharedVariablesService.transaction.properties.rangeRank : undefined,
                                          ['capsule', 'FOND_DE_RAYON'].includes(self.sharedVariablesService.saleChannel.type) ? [self.sharedVariablesService.transaction.properties.rangeName] : self.rangeFilter.selected,
                                          self.rangeRecommendationFilter.selected,
                                          self.sortingField)
        .toPromise().then((elasticSearchResult) => {
        if (!forceReload) {
          self.products = self.products.concat(elasticSearchResult.products);
        } else {
          self.products = elasticSearchResult.products;
          if (!fullLoad) {
            self.goUp();
          }
        }
        self.total = elasticSearchResult.total;
        self.allProductsLoaded = (self.products.length === elasticSearchResult.total);
        self.ngxLoaderService.stop();

        // GA view list item
        this.googleAnalyticsService.viewItemList(this.products, this.sharedVariablesService.transaction?.properties.categoryName);
      });
    }
  }

  /**
   * Go back in the navigation stack.
   */
  goBack() {
    this.location.back();
  }

  /**
   * Scroll event.
   * @param $event
   */
  scroll($event) {
    this.showGoUpArrow = window.scrollY > 0;
    if (this.filterPanelElement) {
      this.sticky = (window.scrollY >= this.filtersTopPosition);
    }
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }

  /**
   * Close search.
   */
  closeSearch() {
    this.searchTerm = undefined;
    this.filterUpdated(undefined);
  }
}
