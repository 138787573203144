<div class="full-width filter-container">
  <div class="filters-title">
    <div class="filters-title-with-badge" matBadge="{{ filtersUsed }}" matBadgePosition="before" matBadgeColor="accent" matBadgeOverlap="false" [matBadgeHidden]="filtersUsed === 0"><strong>FILTRES</strong></div>
  </div>
  <button *ngIf="filtersUsed > 0" (click)="reset()" mat-flat-button color="accent" class="small full-width reset-button">TOUT EFFACER <mat-icon class="font-size">delete</mat-icon></button>
  <div class="filter-content">
    <div *ngFor="let filter of filters">
      <app-filter-single-choice (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="filter.getType() === 'FilterSingleChoice' && filter.options.length > 0"></app-filter-single-choice>
      <app-filter-single-select (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="filter.getType() === 'FilterSingleSelect' && filter.options.length > 0"></app-filter-single-select>
      <app-filter-multiple-choice (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="filter.getType() === 'FilterMultipleChoice' && filter.options.length > 0"></app-filter-multiple-choice>
      <app-filter-multiple-choice-tags (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="filter.getType() === 'FilterMultipleChoiceTags' && filter.options.length > 0"></app-filter-multiple-choice-tags>
      <app-filter-tree-choice (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="(filter.getType() === 'FilterSingleChoiceTree' || filter.getType() === 'FilterMultipleChoiceTree') && filter.options.length > 0"></app-filter-tree-choice>
      <app-filter-search (filterUpdated)="filterUpdated.emit(filter)" [filter]="filter" *ngIf="filter.getType() === 'FilterSearch'"></app-filter-search>
    </div>
  </div>
</div>
