import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterSearch, FilterSingleChoice } from '../../../lib/model/Filter';
import { FilterService } from '../../../lib/services/filter.service';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent implements OnInit, OnDestroy {
  /**
   * Subscription to filter service to auto update the filter upon request.
   */
  private filterServiceSubscription: Subscription;
  /**
   * The filter to initialize the component with.
   */
  @Input() filter: FilterSearch;
  /**
   * Emit the filter upon update.
   */
  @Output() filterUpdated: EventEmitter<FilterSearch> = new EventEmitter();
  /**
   * The user input for search.
   */
  searchTerm: Subject<string> = new Subject<string>();

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.filterServiceSubscription = this.filterService.filterSubject.subscribe((filter) => {
      if (filter.id === this.filter.id) {
        this.filter = <FilterSearch>filter;
        this.filterUpdated.emit(this.filter);
      }
    });

    this.searchTerm.pipe(debounceTime(400)).subscribe((data) => {
      this.filter.value = data;
      this.filterUpdated.emit(this.filter);
    });
  }

  ngOnDestroy() {
    this.filterServiceSubscription.unsubscribe();
  }
}
