import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { slideInAnimation } from './animations';
import { ErrorService } from './lib/services/error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation,
    // animation triggers go here
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private errorSubscription: Subscription;

  constructor(private _iconRegistry: MatIconRegistry,
              private _domSanitizer: DomSanitizer,
              private errorService: ErrorService,
              private snackbar: MatSnackBar) {
    this._iconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/picto/dist/custom.svg'));
  }

  ngOnInit() {
    const self = this;
    this.errorSubscription = this.errorService.error.subscribe((message) => {
      self.snackbar.open(message, undefined, {
        panelClass: ['error-snackbar'],
      });
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
