import { Pipe, PipeTransform } from '@angular/core';
import { SystemeUTransactionDeclination } from '../model/SystemeUTransaction';

/**
 * Pipe to sort items in cart.
 */
@Pipe({
  name: 'sortbynumphoto',
})
export class SortPipe implements PipeTransform {
  transform(systemeUTransactionDeclinations: SystemeUTransactionDeclination[], order: string = 'asc'): SystemeUTransactionDeclination[] {
    return systemeUTransactionDeclinations.sort((c1, c2) => {
      if (parseInt(c1.declination.reference, 10) > parseInt(c2.declination.reference, 10)) {
        return (order === 'asc') ? 1 : -1;
      } else {
        return (order === 'asc') ? -1 : 1;
      }
    });
  }
}
