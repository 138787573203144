import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Advertisement, AdvertisementAdapter } from '../model/Advertisement';

@Injectable()
export class AdvertisementService {

  constructor(private http: HttpClient,
              private advertisementAdapter: AdvertisementAdapter) {
  }

  public getAdvertisements(saleChannelCode: string, slot: string): Observable<Advertisement[]> {
    let params = new HttpParams();
    params = params.set('saleChannelCodes', saleChannelCode);
    params = params.set('slots', slot);
    params = params.set('active', 'true');

    return this.http.get<Advertisement[]>('api/display', {params}).pipe(
      map((data: any[]) => data.map(item => this.advertisementAdapter.adapt(item)))
    );
  }
}
