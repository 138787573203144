import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Customer, CustomerAdapter } from './Customer';
import { Item, ItemAdapter } from './Item';
import { Product } from './Product';
import { ProductVariant } from './ProductVariant';
import { Status } from './Status';
import { TransactionProperties, TransactionPropertiesAdapter } from './TransactionProperties';

export interface TransactionColouredStatus {
  color: string;
  name: string;
}

export interface ItemsMapObject {
  // Product id -> ItemQuantity[]
  [productId: string]: ItemQuantity;
}

export interface ItemQuantity {
  // Ean -> quantity
  [ean: string]: number;
}

export class Transaction {
  id: string;
  saleChannelCode: string;
  customer: Customer;
  properties: TransactionProperties;
  organisationId: string;
  sellerId: string;
  sellerDeviceId: string;
  // CA Vente TTC
  totalAmount: number = 0;
  // CA Cession HT
  totalPurchasingAmount: number = 0;
  // Tx marge brute
  totalMarginRate: number = 0;
  // Qté UVC
  totalSalesUnitCount: number = 0;
  type: string;
  amount: string;
  validationDate: Date;
  currentStatus: Status;
  insufficientStock: boolean;
  private _items: Item[] = [];
  /**
   * Object containing items of the transaction (one item per ean property).
   */
  private itemQuantities: ItemsMapObject = {};

  constructor(id: string,
              saleChannelCode: string,
              customer: Customer,
              properties: TransactionProperties,
              organisationId: string,
              sellerId: string,
              sellerDeviceId: string,
              totalAmount?: number,
              totalPurchasingAmount?: number,
              totalMarginRate?: number,
              totalSalesUnitCount?: number,
              items?: Item[],
              itemQuantities?: ItemsMapObject,
              type?: string,
              amount?: string,
              validationDate?: Date,
              currentStatus?: Status,
              insufficientStock: boolean = false) {
    this.id = id;
    this.saleChannelCode = saleChannelCode;
    this.customer = customer;
    this.properties = properties;
    this.organisationId = organisationId;
    this.sellerId = sellerId;
    this.sellerDeviceId = sellerDeviceId;
    this.totalAmount = totalAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.totalMarginRate = totalMarginRate;
    this.totalSalesUnitCount = totalSalesUnitCount;
    this._items = items;
    this.itemQuantities = itemQuantities;
    this.type = type;
    this.amount = amount;
    this.validationDate = validationDate;
    this.currentStatus = currentStatus;
    this.insufficientStock = insufficientStock;
  }

  get items(): Item[] {
    return this._items;
  }

  get itemsAsObject(): ItemsMapObject {
    return this.itemQuantities;
  }

  /**
   * Return objects to set coloured label status of a transaction.
   */
  public getColouredStatus(): TransactionColouredStatus {
    if (this.currentStatus.code === 'CREATED') {
      if (typeof this.totalSalesUnitCount === 'undefined' || this.totalSalesUnitCount === 0) {
        return { color: 'green', name: 'À saisir'};
      } else {
        return { color: 'orange', name: 'En cours de saisie'};
      }
    } else if (this.currentStatus.code === 'TO_VALIDATE') {
      return { color: 'pink', name: 'Validé'};
    } else { // VALIDATED
      return { color: 'red', name: 'Commande envoyée'};
    }
  }

  /**
   * Reinject items objects from itemsMapObject into transaction items array.
   */
  public reinjectItemsMapObject() {
    // Transform this.itemsMapObject into an array of Item objects with ean and quantity properties
    this._items = [];
    if (this.itemQuantities) {
      for (const [, value] of Object.entries(this.itemQuantities)) {
        for (const [ean, quantity] of Object.entries(value)) {
          this._items.push(<Item>{ean, quantity});
        }
      }
    }
  }

  /**
   * Add / edit / remove a product to/from the transaction using provided quantity.
   * @param variant
   * @param quantity
   */
  public updateProductInTransaction(variant: ProductVariant|Item, quantity: number = 0) {
    // If quantity is 0 or NaN, delete item
    if ((quantity === 0 || isNaN(quantity)) && this.itemQuantities[variant.productId] && this.itemQuantities[variant.productId][variant.ean]) {
      delete this.itemQuantities[variant.productId][variant.ean];
      if (this.itemQuantities[variant.productId].length === 0) {
        delete this.itemQuantities[variant.productId];
      }
    } else { // Add or update
      if (!this.itemQuantities[variant.productId]) {
        this.itemQuantities[variant.productId] = {};
      }
      this.itemQuantities[variant.productId][variant.ean] = quantity;
    }
  }

  /**
   * Return true whether the given product is in transaction.
   * @param product
   */
  public containsProduct(product: Product): boolean {
    return this.itemQuantities.hasOwnProperty(product.id);
  }

  /**
   * Return true whether any of the given ean for product is in transaction.
   * @param productId
   * @param eans
   */
  public containsDeclinationEan(productId: string, eans: string[]): boolean {
    return this.itemQuantities.hasOwnProperty(productId) && Object.keys(this.itemQuantities[productId]).some(v => eans.indexOf(v) !== -1);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    const transactionPropertiesAdapter = new TransactionPropertiesAdapter();
    const customerAdapter = new CustomerAdapter();
    const itemAdapter = new ItemAdapter();

    return new Transaction(
      item.id,
      item.saleChannelCode ? item.saleChannelCode : (item.saleChannel ? item.saleChannel.code : undefined),
      item.customer ? customerAdapter.adapt(item.customer) : undefined,
      transactionPropertiesAdapter.adapt(item.properties),
      item.organisationId,
      item.sellerId,
      item.sellerDeviceId,
      item.totalAmount,
      item.totalPurchasingAmount,
      item.totalMarginRate,
      item.totalSalesUnitCount,
      item.items ? item.items.map(elt => itemAdapter.adapt(elt)) : [],
      item.itemQuantities ? item.itemQuantities : {},
      item.type,
      item.amount,
      item.validationDate ? new Date(item.validationDate) : undefined,
      item.currentStatus,
      item.insufficientStock
    );
  }

  prepare(object: Transaction): any {
    const target = {};
    const itemAdapter: ItemAdapter = new ItemAdapter();
    const customerAdapter: CustomerAdapter = new CustomerAdapter();

    // We prepare and clean transaction
    object.reinjectItemsMapObject();
    Object.assign(target, object);
    if (object.validationDate) {
      target['validationDate'] = target['validationDate'].toISOString();
    }
    target['customer'] = customerAdapter.prepare(object.customer);
    target['items'] = target['_items'].map((elt) => itemAdapter.prepare(elt));
    delete target['_items'];
    delete target['itemsMapObject'];
    delete target['productIds'];

    return target;
  }
}
