<div class="filter app-filter-multivalued-container full-width">
  <mat-label class="mat-label" (click)="toggleVisibility()">{{ filter.name }}
    <mat-icon class="link" *ngIf="!filter.visible">keyboard_arrow_right</mat-icon>
    <mat-icon class="link" *ngIf="filter.visible">keyboard_arrow_down</mat-icon>
  </mat-label>
  <div class="filter-content" [ngClass]="!filter.visible ? 'collapsed' : ''">
    <mat-chip-listbox [multiple]="true">
      <mat-chip-option *ngFor="let filterOption of filter.options"
                color="accent"
                [selectable]="true"
                [selected]="filter.selected.indexOf(filterOption.id) > -1"
                (click)="updateSelected(filterOption.id, $event)">{{ filterOption.name }}</mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
