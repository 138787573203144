import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './lib/helpers/auth-guards';
import { AuthInterceptor } from './lib/helpers/auth.interceptor';
import { ErrorInterceptor } from './lib/helpers/error.interceptor';
import { CustomReuseStrategy } from './lib/helpers/routing';
import { LibModule } from './lib/lib.module';
import { DragScrollModule } from 'ngx-drag-scroll';
registerLocaleData(localeFr, 'fr');

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExhibitionsComponent } from './pages/exhibitions/exhibitions.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductComponent } from './pages/product/product.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FolderComponent } from './pages/folder/folder.component';
import { ProductItemComponent } from './partials/product-item/product-item.component';
import { CartComponent } from './pages/cart/cart.component';
import { CartProductComponent } from './partials/cart-product/cart-product.component';
import { ResetPasswordComponent } from './partials/reset-password/reset-password.component';
import { RemoveProductConfirmComponent } from './partials/remove-product-confirm/remove-product-confirm.component';
import { RemoveFolderConfirmComponent } from './partials/remove-folder-confirm/remove-folder-confirm.component';
import { ProductItemSmallComponent } from './partials/product-item-small/product-item-small.component';
import { ContactComponent } from './partials/contact/contact.component';
import { PlanogramComponent } from './pages/planogram/planogram.component';
import { PlanogramPrintComponent } from './pages/planogram-print/planogram-print.component';
import { PasswordComponent } from './pages/password/password.component';
import { EditorialComponent } from './pages/editorial/editorial.component';
import { LoginAdminComponent } from './pages/login-admin/login-admin.component';
import { BreadcrumbComponent } from './partials/breadcrumb/breadcrumb.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';

// Configure loader
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00828c',
  bgsOpacity: 0.5,
  bgsPosition: 'center-center',
  bgsSize: 90,
  bgsType: 'fading-circle',
  blur: 4,
  delay: 0,
  fgsColor: '#00828c',
  fgsPosition: 'center-center',
  fgsSize: 90,
  fgsType: 'fading-circle',
  gap: 24,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(255,255,255,0.5)',
  pbColor: '#00828c',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: false,
  text: '',
  textColor: '#00828c',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 250,
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ExhibitionsComponent,
        DashboardComponent,
        FolderComponent,
        ProductItemComponent,
        ProductComponent,
        CartComponent,
        CartProductComponent,
        ResetPasswordComponent,
        RemoveProductConfirmComponent,
        RemoveFolderConfirmComponent,
        ProductItemSmallComponent,
        ContactComponent,
        PlanogramComponent,
        PlanogramPrintComponent,
        PasswordComponent,
        EditorialComponent,
        LoginAdminComponent,
        BreadcrumbComponent,
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDialogModule,
    MatRippleModule,
    MatSnackBarModule,
    LibModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxImgZoomModule,
    InfiniteScrollModule,
    DragScrollModule,
    MatCheckboxModule,
    NgxImageZoomModule,
  ],
    providers: [
        AuthGuard,
        AuthInterceptor,
        ErrorInterceptor,
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: 0 } },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
