import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { slideInAnimation } from '../../animations';
import { SaleChannel } from '../../lib/model/SaleChannel';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SaleChannelService } from '../../lib/services/sale-channel.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { AdvertisementService } from '../../lib/services/advertisement.service';

export interface ExhibitionsImages {
  [saleChannelCode: string]: string;
}

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ],
})
export class ExhibitionsComponent implements OnInit {
  saleChannels: SaleChannel[];
  saleChannelsImages: ExhibitionsImages = {};

  constructor(public router: Router,
              private saleChannelService: SaleChannelService,
              private authenticationService: AuthenticationService,
              private sharedVariablesService: SharedVariablesService,
              private advertisementService: AdvertisementService,
              private ngxService: NgxUiLoaderService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    // Clear global variables (no exhibition / transaction selected)
    this.sharedVariablesService.clear();
    this.ngxService.startLoader('loader-exhibitions');
    const self = this;

    // Fetch running exhibitions
    this.saleChannelService.getRunningExhibitions(this.authenticationService.getCurrentUser().isInstanceManager()).pipe(take(1)).subscribe((saleChannels) => {
      self.saleChannels = saleChannels;
      self.ngxService.stopLoader('loader-exhibitions');

      for (const saleChannel of saleChannels) {
        // We request the advertising (sale channel image)
        self.advertisementService.getAdvertisements(saleChannel.code, 'EXHIBITION_IMAGE').toPromise().then((advertisements) => {
          if (advertisements.length > 0) {
            self.saleChannelsImages[saleChannel.code] = advertisements[0].mediaUrl;
          }
        });
      }
    });
  }

  /**
   * Open an exhibition.
   * @param saleChannel
   */
  openExhibition(saleChannel: SaleChannel) {
    this.sharedVariablesService.saleChannel = saleChannel;

    this.googleAnalyticsService.eventEmitter('select_exhibition', { exhibitionName: saleChannel.name });

    // We determine whether there is at least one editorial advertisement and navigate accordingly (to editorial / dashboard)
    this.advertisementService.getAdvertisements(saleChannel.code, 'EXHIBITION_EDITORIAL,EXHIBITION_OPERATION_MODE,EXHIBITION_COMMAND_TOOL,EXHIBITION_COLLECTION_PRESENTATION').toPromise().then((advertisements) => {
      if (advertisements.length === 0) {
        this.router.navigate(['/tableau-de-bord', saleChannel.code]);
      } else {
        this.router.navigate(['/editorial', saleChannel.code]);
      }
    }).catch((error) => {
      this.router.navigate(['/tableau-de-bord', saleChannel.code]);
    });
  }
}
