import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remove-product-confirm',
  templateUrl: './remove-product-confirm.component.html',
  styleUrls: [],
})
export class RemoveProductConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
