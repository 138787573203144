<div class="filter app-filter-single-choice-container full-width">
  <mat-label class="mat-label" (click)="toggleVisibility()">{{ filter.name }}
    <mat-icon class="link" *ngIf="!filter.visible">keyboard_arrow_right</mat-icon>
    <mat-icon class="link" *ngIf="filter.visible">keyboard_arrow_down</mat-icon>
  </mat-label>
  <div class="filter-content" [ngClass]="!filter.visible ? 'collapsed' : ''">
    <mat-form-field>
      <mat-label>Filtrer</mat-label>
      <input matInput (input)="searchTerm.next($event.target.value)" autocomplete="off">
    </mat-form-field>
    <app-option-list [options]="filter.options"
                     [filterTerm]="filterTerm"
                     [selectedOptions]="filter.selected"
                     (optionsSelected)="updateSelected($event)"
                     [multi]="isMultichoice()"></app-option-list>
  </div>
</div>
