import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgxImgZoomService } from 'ngx-img-zoom';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { Item } from '../../lib/model/Item';
import { Product } from '../../lib/model/Product';
import { ProductDeclination, ProductDeclinationImage } from '../../lib/model/ProductDeclination';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { ProductService } from '../../lib/services/product.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

class DialogData {
  popin: boolean = false;
  fromCart: boolean = false;
  fromPlanogram: boolean = false;
  productId: string;
  declinationId: string;
}

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
  @Input() product: Product;
  @Input() withDetails: boolean = false;
  public productImage: ProductDeclinationImage;
  public imageIndex: number = 0;
  public quantitiesForm: UntypedFormGroup;
  public isAlreadyInTransaction: boolean = false;
  public loading: boolean = false;
  public transactionIsClosed: boolean = false;
  public cartLabelAdd: string = 'AU PANIER';
  public cartLabelEdit: string = 'LE PANIER';
  public isWishlist: boolean = false;
  public fullscreenImgSrc: string = undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              @Optional() private dialogRef: MatDialogRef<ProductItemComponent>,
              private ngxImgZoom: NgxImgZoomService,
              private ngxLoaderService: NgxUiLoaderService,
              public transactionService: TransactionService,
              public productService: ProductService,
              public sharedVariablesService: SharedVariablesService,
              private snackbar: MatSnackBar,
              public googleAnalyticsService: GoogleAnalyticsService) {
    // Zoom levels for product image
    this.ngxImgZoom.setZoomBreakPoints([{w: 75, h: 75}, {w: 100, h: 100}, {w: 125, h: 125}, {w: 150, h: 150}, {w: 175, h: 175}, {w: 200, h: 200}]);
    this.quantitiesForm = new UntypedFormGroup({});
  }

  ngOnInit() {
    this.isWishlist = (this.sharedVariablesService.saleChannel.isWishlist());

    if (this.isWishlist) {
      this.cartLabelAdd = 'À MA SÉLECTION';
      this.cartLabelEdit = 'MA SÉLECTION';
    }

    // We determine if the transaction is already sent
    this.transactionIsClosed = this.sharedVariablesService.transaction.currentStatus.code !== 'CREATED';

    if (!this.product && this.data.productId) { // If no product in input, use data provided by dialog calling to retrieve product and select declination
      this.ngxLoaderService.startBackgroundLoader('loader-product-popin');
      this.productService.getProduct(this.data.productId).pipe(take(1)).subscribe((product) => {
        this.product = product;
        this.product.selectDeclination(this.data.declinationId);
        this.chooseDeclination(this.product.selectedDeclination);
        this.ngxLoaderService.stopBackgroundLoader('loader-product-popin');
      });
    } else {
      this.chooseDeclination(this.product.selectedDeclination);
    }
  }

  /**
   * Change selected product declination.
   * @param declination
   */
  public chooseDeclination(declination: ProductDeclination) {
    if (declination) {
      this.product.selectedDeclination = declination;
      this.selectNthImageOfDeclination();

      // Update quantities form
      this.quantitiesForm = new UntypedFormGroup({});
      this.product.selectedDeclination.productVariants.forEach((variant) => {
        this.quantitiesForm.addControl(variant.ean,
          new UntypedFormControl(this.sharedVariablesService.transaction.itemsAsObject[variant.productId] && this.sharedVariablesService.transaction.itemsAsObject[variant.productId][variant.ean]
            ? this.sharedVariablesService.transaction.itemsAsObject[variant.productId][variant.ean] : (this.sharedVariablesService.saleChannel.type === 'FOND_DE_RAYON' ? 1 : undefined),
            Validators.pattern('^\\d+$')));
      });

      this.isAlreadyInTransaction = this.sharedVariablesService.transaction.containsProduct(this.product);

      if (this.transactionIsClosed || !this.sharedVariablesService.saleChannel.isOpen(true)) {
        // We disable the form if edition is forbidden
        this.quantitiesForm.disable();
      }
    }
  }

  /**
   * Count total packages for declination (sum of variants quantities inputs).
   */
  public countPackages(): number {
    return Object.values(this.quantitiesForm.value).reduce<number>((sum: number, qty: string) => {
      if (!isNaN(Number(qty)) && qty) {
        sum += parseInt(qty, 10);
      }
      return sum;
    }, 0);
  }

  /**
   * Select first image of the currently selected declination.
   */
  private selectNthImageOfDeclination(index: number = 0) {
    if (this.product.selectedDeclination.images && this.product.selectedDeclination.images.length > index) {
      this.imageIndex = index;
      this.productImage = this.product.selectedDeclination.images[this.imageIndex];
    } else if (this.product.selectedDeclination.images && this.product.selectedDeclination.images.length > 0) {
      this.imageIndex = 0;
      this.productImage = this.product.selectedDeclination.images[this.imageIndex];
    } else {
      this.productImage = undefined;
    }
    // We change full screen image url if displayed
    if (this.productImage && this.fullscreenImgSrc) {
      this.fullscreenImgSrc = this.product.getHdImageUrl(this.productImage.url);
    }
  }

  public selectNextImage() {
    this.imageIndex++;
    this.googleAnalyticsService.eventEmitter('click_other_photo', { productName: this.product.name, photoNumber: this.imageIndex });
    this.selectNthImageOfDeclination(this.imageIndex);
  }

  public selectPreviousImage() {
    this.imageIndex--;
    if (this.imageIndex < 0 && this.product.selectedDeclination.images.length > 0) {
      this.imageIndex = this.product.selectedDeclination.images.length - 1;
    }
    this.googleAnalyticsService.eventEmitter('click_other_photo', { productName: this.product.name, photoNumber: this.imageIndex });
    this.selectNthImageOfDeclination(this.imageIndex);
  }

  /**
   * Quantities form processing.
   */
  submitQuantities() {
    this.loading = true;
    const modifiedItems: Item[] = [];

    for (const [key, value] of Object.entries(this.quantitiesForm.value)) {
      modifiedItems.push(<Item>{ean: key, quantity: (value ? value : 0)});
    }

    const self = this;

    this.transactionService.updateModifiedItemsSystemU(this.sharedVariablesService.transaction, modifiedItems,
      this.data && this.data.fromCart, this.data && this.data.fromCart && (this.sharedVariablesService.saleChannel.type === 'capsule')).pipe(take(1)).subscribe((systemeUTransaction) => {
      self.sharedVariablesService.transaction = systemeUTransaction.transaction;
      self.loading = false;
      self.isAlreadyInTransaction = this.sharedVariablesService.transaction.containsProduct(this.product);
      self.snackbar.open('Panier mis à jour avec succès.', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
      this.googleAnalyticsService.addItemToCart(this.product, '');

      if (self.data && (self.data.fromCart || self.data.fromPlanogram)) {
        // Close this dialog and return Systeme U transaction
        self.dialogRef.close({systemeUTransaction});
      }
    }, (err) => {
      self.loading = false;
    });
  }

  showFullscreen(hdImageUrl: string) {
    this.googleAnalyticsService.eventEmitter('click_enlarge_photo', { productName: this.product.name });
    this.fullscreenImgSrc = hdImageUrl;
  }

  hideFullscreen() {
    this.fullscreenImgSrc = undefined;
  }

  changedTab($event: MatTabChangeEvent) {
    this.googleAnalyticsService.eventEmitter('click_product_info_tab', { productName: this.product.name, tabName: $event.tab.textLabel });
  }
}
