<div class="password-component">
  <div class="text-center">
    <img class="mt50 mb50 logo" src="/assets/images/systeme_u_salon.png" alt="Système U - Salon web">
    <h2>Réinitialisation de votre mot de passe</h2>
    <mat-card appearance="outlined" class="password-card">
      <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <mat-card-content>
          <mat-form-field class="full-width">
            <mat-label>Identifiant</mat-label>
            <input matInput disabled [value]="email">
          </mat-form-field>
          <p class="small">Votre mot de passe doit contenir au moins 8 caractères.</p>
          <mat-form-field class="full-width">
            <mat-label>Nouveau mot de passe</mat-label>
            <input type="password" matInput formControlName="password"/>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Confirmez le nouveau mot de passe</mat-label>
            <input type="password" matInput formControlName="confirmPassword"/>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button type="submit" mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading || passwordForm.invalid">VALIDER</button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
