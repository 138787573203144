<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="brand ? brand : (category ? category.name : undefined)"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" title="Retour" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2><ng-container *ngIf="sharedVariablesService.transaction?.properties.categoryName">{{ sharedVariablesService.transaction?.properties.categoryName }} <ng-container *ngIf="sharedVariablesService.transaction?.properties.rangeName"> - </ng-container></ng-container>
        <ng-container *ngIf="sharedVariablesService.transaction?.properties.rangeName">{{ sharedVariablesService.transaction?.properties.rangeName }}</ng-container>
        <ng-container *ngIf="sharedVariablesService.saleChannel.type === 'permanent'">- {{ sharedVariablesService.transaction?.customer.priceScaleCode }}</ng-container></h2>
    </div>
    <div class="flex">
      <h2><span class="lighter">Du</span> {{ sharedVariablesService.saleChannel.openingDate | date : 'shortDate' }}
        <span class="lighter">au</span> {{ sharedVariablesService.saleChannel.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le classeur" title="Quitter le classeur" class="link" (click)="googleAnalyticsService.eventEmitter('click_exit_exhibition')" [routerLink]="['/tableau-de-bord', sharedVariablesService.saleChannel.code]"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<app-menu></app-menu>
<div class="row section">
  <div class="col-md-3">
    <div [class.sticky]="sticky" #filterPanel class="filter-panel-wrapper">
      <app-filter-panel [filters]="filters" (filterUpdated)="filterUpdated($event)"></app-filter-panel>
    </div>
  </div>
  <div class="col-md-9 products-list" infiniteScroll [infiniteScrollDisabled]="disableScroll" (scrolled)="loadProducts()" ngxUiLoaderBlurred blur="3">
    <div>
      <ng-container *ngIf="category">Assortiment : <strong>{{ category?.name }}</strong></ng-container>
      <span class="ml20" *ngIf="brand">Marque : <strong>{{ brand }}</strong></span>
      <span class="ml20" *ngIf="searchTerm">Recherche : &laquo; <strong>{{ searchTerm }} </strong>&raquo;
      <span class="link close-search" (click)="closeSearch()"><mat-icon class="font-size">close</mat-icon></span></span>
      <span class="ml20">{{ total }} produit{{ total > 1 ? 's' : ''}}</span>
      <div class="sort">
        Trier par :
        <mat-form-field>
          <mat-select [(value)]="sortingField" (selectionChange)="filterUpdated()">
            <mat-option *ngFor="let s of sortingFields" [value]="s.code">{{ s.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <app-product-item *ngFor="let product of products" [product]="product"></app-product-item>
  </div>
</div>
<button mat-mini-fab class="top-button" *ngIf="showGoUpArrow" (click)="goUp()"><mat-icon>keyboard_arrow_up</mat-icon></button>
