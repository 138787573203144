import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilterMultipleChoiceTagsComponent } from '../partials/filter/filter-multiple-choice-tags/filter-multiple-choice-tags.component';
import { FilterMultipleChoiceComponent } from '../partials/filter/filter-multiple-choice/filter-multiple-choice.component';
import { FilterSearchComponent } from '../partials/filter/filter-search/filter-search.component';
import { FilterSingleChoiceComponent } from '../partials/filter/filter-single-choice/filter-single-choice.component';
import { FilterSingleSelectComponent } from '../partials/filter/filter-single-select/filter-single-select.component';
import { FilterTreeChoiceComponent } from '../partials/filter/filter-tree-choice/filter-tree-choice.component';
import { OptionListComponent } from '../partials/filter/filter-tree-choice/option-list/option-list.component';
import { FilterComponent } from '../partials/filter/filter.component';
import { HeaderComponent } from '../partials/header/header.component';
import { MenuComponent } from '../partials/menu/menu.component';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { FilterService } from './services/filter.service';
import { SaleChannelService } from './services/sale-channel.service';
import { SharedVariablesService } from './services/shared-variables.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PricePipe } from './helpers/price.pipe';
import { SortPipe } from './helpers/sort.pipe';
import { AdvertisementService } from './services/advertisement.service';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    FilterSearchComponent,
    FilterSingleChoiceComponent,
    FilterSingleSelectComponent,
    FilterMultipleChoiceComponent,
    FilterComponent,
    FilterTreeChoiceComponent,
    FilterMultipleChoiceTagsComponent,
    OptionListComponent,
    PricePipe,
    SortPipe,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRadioModule,
    MatCheckboxModule,
    MatBadgeModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    FilterSearchComponent,
    FilterSingleChoiceComponent,
    FilterSingleSelectComponent,
    FilterMultipleChoiceComponent,
    FilterComponent,
    FilterTreeChoiceComponent,
    FilterMultipleChoiceTagsComponent,
    OptionListComponent,
    MenuComponent,
    HeaderComponent,
    HttpClientModule,
    PricePipe,
    SortPipe,
  ],
  providers: [
    FilterService,
    AuthenticationService,
    ErrorInterceptor,
    AuthInterceptor,
    SaleChannelService,
    AdvertisementService,
    SharedVariablesService,
  ],
})
export class LibModule {
}
