import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { Item } from '../../lib/model/Item';
import { SystemeUTransaction, SystemeUTransactionDeclination } from '../../lib/model/SystemeUTransaction';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import { ProductItemComponent } from '../product-item/product-item.component';
import { RemoveProductConfirmComponent } from '../remove-product-confirm/remove-product-confirm.component';

@Component({
  selector: 'app-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss'],
})
export class CartProductComponent implements OnInit {
  @Input() sysDeclination: SystemeUTransactionDeclination;
  @Input() editable: boolean = false;
  @Input() stockIssue: boolean = false;
  /**
   * Emit the Systeme U transaction upon update.
   */
  @Output() transactionUpdated: EventEmitter<SystemeUTransaction> = new EventEmitter();
  private isWishlist: boolean = false;

  constructor(private dialog: MatDialog,
              public sharedVariablesService: SharedVariablesService,
              private ngxLoaderService: NgxUiLoaderService,
              private snackbar: MatSnackBar,
              private transactionService: TransactionService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.isWishlist = (this.sharedVariablesService.saleChannel.isWishlist());
  }

  /**
   * Opens a product view for the user to change its choices.
   */
  editProduct(productId: string, declinationId: string) {
    const dialogRef = this.dialog.open(ProductItemComponent, {
      width: '800px',
      data: { popin: true, fromCart: true, productId, declinationId },
    });

    dialogRef.afterClosed().toPromise().then((data) => {
      if (data && data.systemeUTransaction) {
        this.transactionUpdated.emit(data.systemeUTransaction);
      }
    });
  }

  /**
   * Remove a product declination from cart (by removing all its variants).
   * @param variants
   */
  deleteFromCart(variants: Item[]) {
    const dialogRef = this.dialog.open(RemoveProductConfirmComponent);

    this.googleAnalyticsService.removeItemFromCart(variants[0], 'cart');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngxLoaderService.startLoader('cart_' + this.sysDeclination.declination.id);

        // Remove each item (ean variant) from the transaction
        const modifiedItems: Item[] = [];
        variants.forEach((variant) => {
          modifiedItems.push(<Item>{ean: variant.ean, quantity: 0});
        });

        const self = this;
        this.transactionService.updateModifiedItemsSystemU(this.sharedVariablesService.transaction, modifiedItems, true,
          (this.sharedVariablesService.saleChannel.type === 'capsule')).pipe(take(1)).subscribe((systemeUTransaction) => {
          self.sharedVariablesService.transaction = systemeUTransaction.transaction;
          self.ngxLoaderService.stopLoader('cart_' + self.sysDeclination.declination.id);
          self.snackbar.open('Panier mis à jour avec succès.', undefined, {
            panelClass: ['success-snackbar'],
            duration: 2500,
          });
          // Return Systeme U transaction
          self.transactionUpdated.emit(systemeUTransaction);
        }, (err) => {
          self.ngxLoaderService.stopLoader('cart_' + self.sysDeclination.declination.id);
        });
      }
    });
  }
}
