<div class="login-component">
  <div class="text-center">
    <img class="mt50 mb50 logo" src="/assets/images/systeme_u_salon.png" alt="Système U - Salon web">
    <h2>Accès administrateur</h2>
    <mat-card appearance="outlined" class="login-card">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!user">
        <mat-card-content>
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput formControlName="login">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Mot de passe</mat-label>
            <input type="password" matInput formControlName="password"/>
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions class="text-center pb20">
          <button type="submit" mat-raised-button color="accent" [class.spinner]="loading"
                  [disabled]="loading">CONNEXION
          </button>
        </mat-card-actions>
      </form>

      <form [formGroup]="organisationSelectionForm" (ngSubmit)="onSubmitOrganisationSelect()" *ngIf="user">
        <mat-card-content>
          <p class="small">Sélectionnez le magasin dont vous souhaitez voir les classeurs.</p>
          <mat-form-field class="full-width">
            <mat-label>Magasin</mat-label>
            <mat-select formControlName="organisation">
              <ng-container *ngFor="let organisation of user.organisations">
                <mat-option *ngIf="organisation.type === 'OUTLET'" [value]="organisation.id">{{ organisation.name }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions class="text-center pb20">
          <button type="submit" mat-raised-button color="accent" [class.spinner]="loading" [disabled]="loading || organisationSelectionForm.invalid">OK
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>

  <div class="version">v{{ version }}</div>

  <div class="customer-login">
    <button type="button" class="mb10 mr10 small" mat-raised-button [routerLink]="'/login'">ACCÈS UTILISATEUR</button>
  </div>
</div>
