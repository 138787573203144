import {Injectable} from "@angular/core";
import {Adapter} from "../interfaces/adapter.interface";
import {Transaction} from "./Transaction";

export class Planogram {
  elements: PlanogramElement[];

  constructor(elements: PlanogramElement[]) {
    this.elements = elements;
  }
}

export class PlanogramElement {
  elementNumber: number;
  items: PlanogramItem[];

  constructor(elementNumber: number, items: PlanogramItem[]) {
    this.elementNumber = elementNumber;
    this.items = items;
  }
}

export class PlanogramItem {
  eans: string[];
  productId: string;
  declinationId: string;
  declinationReference: string;
  mustHave: boolean;
  height: number;
  width: number;
  x: number;
  y: number;
  imageUrl: string;

  constructor(eans: string[],
              productId: string,
              declinationId: string,
              declinationReference: string,
              mustHave: boolean,
              height: number,
              width: number,
              x: number,
              y: number,
              imageUrl: string) {
    this.eans = eans;
    this.productId = productId;
    this.declinationId = declinationId;
    this.declinationReference = declinationReference;
    this.mustHave = mustHave;
    this.height = height;
    this.width = width;
    this.x = x;
    this.y = y;
    this.imageUrl = imageUrl;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlanogramAdapter implements Adapter<Planogram> {
  adapt(item: any): Planogram {
    const planogramElementAdapter: PlanogramElementAdapter = new PlanogramElementAdapter();
    return new Planogram(item.elements ? item.elements.map(elt => planogramElementAdapter.adapt(elt)) : []);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlanogramElementAdapter implements Adapter<PlanogramElement> {
  adapt(item: any): PlanogramElement {
    const planogramItemAdapter: PlanogramItemAdapter = new PlanogramItemAdapter();
    return new PlanogramElement(
      item.elementNumber,
      item.items ? item.items.map(elt => planogramItemAdapter.adapt(elt)) : []
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlanogramItemAdapter implements Adapter<PlanogramItem> {
  adapt(item: any): PlanogramItem {
    return new PlanogramItem(
      item.eans,
      item.productId,
      item.declinationId,
      item.declinationReference,
      item.mustHave,
      item.height,
      item.width,
      item.x,
      item.y,
      item.imageUrl
    );
  }
}
