import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../lib/model/User';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { ResetPasswordComponent } from '../../partials/reset-password/reset-password.component';
import packageVersion from '../../../../package.json';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit {
  version: string = packageVersion.version;
  public loginForm: UntypedFormGroup;
  public organisationSelectionForm: UntypedFormGroup;
  public loading: boolean = false;
  public user: User;

  constructor(public dialog: MatDialog,
              public authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      login: new UntypedFormControl(undefined, Validators.compose([Validators.required, Validators.email])),
      password: new UntypedFormControl(undefined, Validators.required),
    });

    this.organisationSelectionForm = new UntypedFormGroup({
      organisation: new UntypedFormControl(undefined, Validators.required),
    });

    this.authenticationService.logout(false);
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const self = this;

    this.authenticationService.login(this.loginForm.get('login').value, this.loginForm.get('password').value, 'USER').subscribe((user) => {
      const subject = new Subject<User>();
      subject.next(user);

      self.authenticationService.currentUserSubject.next(user);
      self.loading = false;

      self.user = user;
    }, (error) => {
      self.loading = false;
    });
  }

  openResetPasswordDialog(): void {
    const dialogRef = this.dialog.open(ResetPasswordComponent, { width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * Organisation selection form processing.
   */
  onSubmitOrganisationSelect() {
    this.user.selectOrganisation(this.organisationSelectionForm.get('organisation').value);
    this.authenticationService.currentUserSubject.next(this.user);
    this.authenticationService.saveUser();
    this.googleAnalyticsService.eventEmitter('login', { organisation: this.user.organisation.name });

    // Redirect to exhibitions page
    this.router.navigate(['/salons']);
  }
}
