<app-header></app-header>
<app-breadcrumb [saleChannel]="sharedVariablesService.saleChannel" [transaction]="sharedVariablesService.transaction" [currentPage]="'Tableau de bord'"></app-breadcrumb>
<mat-toolbar class="header-toolbar" color="primary">
  <div class="section flex">
    <div class="text-left flex">
      <button mat-icon-button aria-label="Retour" title="Retour" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2><span class="lighter">Bienvenue sur le </span>{{ saleChannel?.name }}</h2>
    </div>
    <div class="flex">
      <h2><span class="lighter" *ngIf="!saleChannel?.isWishlist()">Fin de la transmission des commandes le</span>
        <span class="lighter" *ngIf="saleChannel?.isWishlist()">Fin de sélection de la collection le</span>
        {{ saleChannel?.closingDate | date : 'shortDate' }}</h2>
      <button mat-icon-button aria-label="Quitter le salon" title="Quitter le salon" class="link" (click)="googleAnalyticsService.eventEmitter('click_exit_exhibition')" routerLink="/salons"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<div class="section pt20 advertisement" *ngIf="advertisement">
  <img src="{{ advertisement.mediaUrl }}" alt="{{ advertisement.name }}" />
</div>
<div class="section p20 create-folder">
  <div class="row" *ngIf="saleChannel.type === 'permanent'">
    <form [formGroup]="form" (ngSubmit)="createTransaction()">
      <mat-form-field class="col-md-3">
        <mat-select placeholder="Assortiments" formControlName="assortiment" (selectionChange)="updateElements($event.value)">
          <mat-option *ngFor="let category of categories" [value]="category">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-select placeholder="Nombre d'éléments" formControlName="elts">
          <mat-option *ngFor="let elt of elements" [value]="elt">{{ elt.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-select placeholder="Tarif" formControlName="tarif">
          <mat-option value="T1">T1</mat-option>
          <mat-option value="T2">T2</mat-option>
          <mat-option value="T3">T3</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-3">
        <button mat-raised-button type="submit" color="accent" [disabled]="!form.valid || loadingCreate" [class.spinner]="loadingCreate">CRÉER UN CLASSEUR</button>
      </div>
    </form>
  </div>
  <div class="row" *ngIf="saleChannel.type === 'capsule'">
    <form [formGroup]="formCapsule" (ngSubmit)="createCapsuleTransaction()">
      <mat-form-field class="col-md-4 col-md-offset-3">
        <mat-select placeholder="Capsule" formControlName="elts">
          <mat-option *ngFor="let elt of elements" [value]="elt">{{ elt.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-3">
        <button mat-raised-button type="submit" color="accent" [disabled]="!formCapsule.valid || loadingCreate" [class.spinner]="loadingCreate">CRÉER UN CLASSEUR</button>
      </div>
    </form>
  </div>
  <div class="row" *ngIf="saleChannel.type === 'FOND_DE_RAYON'">
    <form [formGroup]="formCapsule" (ngSubmit)="createCapsuleTransaction()">
      <mat-form-field class="col-md-4 col-md-offset-3">
        <mat-select placeholder="Rayon" formControlName="elts">
          <mat-option *ngFor="let elt of elements" [value]="elt">{{ elt.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-3">
        <button mat-raised-button type="submit" color="accent" [disabled]="!formCapsule.valid || loadingCreate" [class.spinner]="loadingCreate">CRÉER UN CLASSEUR</button>
      </div>
    </form>
  </div>
  <div class="row" *ngIf="saleChannel.type === 'bazar'">
    <form [formGroup]="formBazar" (ngSubmit)="createBazarTransaction()">
      <mat-form-field class="col-md-4 col-md-offset-3">
        <mat-select placeholder="Catalogue" formControlName="assortiment">
          <mat-option *ngFor="let category of categories" [value]="category">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-3">
        <button mat-raised-button type="submit" color="accent" [disabled]="!formBazar.valid || loadingCreate" [class.spinner]="loadingCreate">CRÉER UN CLASSEUR</button>
      </div>
    </form>
  </div>
  <div class="stocks-warning" *ngIf="saleChannel.type === 'capsule' && atLeastOneOrderToSend">
    <mat-icon class="font-size">warning</mat-icon> La disponibilité d'un produit <strong>n'est pas garantie avant la clôture et transmission de votre commande.</strong>
  </div>
</div>
<div>
  <div class="section classeurs">
    <div class="classeur" [class.warning]="transaction.insufficientStock" *ngFor="let transaction of transactions">
      <div class="title">
        <div class="delete" *ngIf="transaction.currentStatus.code === 'CREATED'">
          <button mat-icon-button (click)="deleteFolder(transaction)" title="Supprimer"><mat-icon aria-label="Supprimer">delete</mat-icon></button>
        </div>
        <div class="reopen" *ngIf="(transaction.currentStatus.code === 'TO_VALIDATE')
         || (transaction.currentStatus.code === 'VALIDATED' && authenticationService.getCurrentUser().isInstanceManager())">
          <button mat-icon-button (click)="reopenFolder(transaction)" title="Ré-ouvrir"><mat-icon aria-label="Ré-ouvrir">lock_open</mat-icon></button>
        </div>
        <ng-container *ngIf="saleChannel.type === 'permanent' || saleChannel.type === 'bazar'">{{ transaction.properties.categoryName }}</ng-container>
        <ng-container *ngIf="saleChannel.type !== 'bazar'"> {{ transaction.properties.rangeName }} - {{ transaction.customer.priceScaleCode }}</ng-container>
        <div class="alert" *ngIf="transaction.insufficientStock"><mat-icon color="white" class="font-size">warning</mat-icon> Stock insuffisant</div>
      </div>
      <div class="content" matRipple (click)="openFolder(transaction)">
        <div class="text-center mb20" *ngIf="!saleChannel.isWishlist()"><span class="status" [ngClass]="transaction.getColouredStatus().color">{{ transaction.getColouredStatus().name }}</span></div>
        <div class="line flex" *ngIf="!saleChannel.isWishlist()"><span>Qté UVC :</span><strong>{{ transaction.totalSalesUnitCount ? transaction.totalSalesUnitCount : '0' }}</strong></div>
        <mat-divider *ngIf="!saleChannel?.isWishlist()"></mat-divider>
        <div class="line flex"><span>CA Cession HT :</span><strong>{{ transaction.totalPurchasingAmount | currency : 'EUR'}}</strong></div>
        <mat-divider></mat-divider>
        <div class="line flex"><span>CA Vente TTC :</span><strong>{{ transaction.totalAmount | currency : 'EUR' }}</strong></div>
        <mat-divider></mat-divider>
        <div class="line flex"><span>Tx marge brute :</span><strong>{{ transaction.totalMarginRate | percent }}</strong></div>
      </div>
    </div>
    <div class="clear"></div>
    <ngx-ui-loader [loaderId]="'loader-transactions'"></ngx-ui-loader>
  </div>


  <div class="footer" *ngIf="transactions.length > 0">
    <div class="section p20">
      <div class="row">
        <div class="col-md-8" [ngClass]="orderIsOkToBeSent ? 'mt10' : 'mt20'">
          <div class="float-left mr30"><strong class="medium">TOTAL</strong> <br>{{ transactions.length }} classeur(s)</div>
          <div class="float-left mr30" *ngIf="!saleChannel.isWishlist()">Qté UVC <br><strong class="medium">{{ totalSalesUnitCount }}</strong></div>
          <div class="float-left mr30">CA Cession HT <br><strong class="medium">{{ totalPurchasingAmount | currency : 'EUR' }}</strong></div>
          <div class="float-left mr30">CA Ventes TTC <br><strong class="big">{{ totalAmount | currency : 'EUR' }}</strong></div>
          <div class="float-left mr30">Tx marge brute <br><strong class="medium">{{ totalMarginRate | percent }}</strong></div>
        </div>
        <div class="col-md-4 text-right">
          <button mat-raised-button color="accent" *ngIf="!saleChannel.isWishlist() && authenticationService.getCurrentUser().isStoreManager()" [disabled]="!orderIsOkToBeSent" (click)="finalizeOrder()" class="send-button">CLÔTURER ET TRANSMETTRE<br>MA COMMANDE</button>
          <a *ngIf="printUrl" mat-button class="send-button small" (click)="googleAnalyticsService.eventEmitter('click_print_selected_product', { exhibitionName: sharedVariablesService.saleChannel.name });" [href]="printUrl" target="_blank"><mat-icon matIconPrefix aria-hidden="false" aria-label="Imprimer">print</mat-icon> Imprimer mes produits {{ !saleChannel.isWishlist() ? 'commandés' : 'sélectionnés' }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
