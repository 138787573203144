import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { User, UserAdapter } from '../model/User';
import { Customer } from '../model/Customer';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public currentUser: Observable<User|Customer>;
  public currentUserSubject: BehaviorSubject<User|Customer>;
  readonly instanceId: string = 'systemeu';

  constructor(private http: HttpClient, private router: Router, private userAdapter: UserAdapter) {
    if (localStorage.getItem('systemeUUser')) {
      this.currentUserSubject = new BehaviorSubject<User>(this.userAdapter.adapt(JSON.parse(localStorage.getItem('systemeUUser'))));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(undefined);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getCurrentUser(): User {
    return this.currentUserSubject.getValue();
  }

  /**
   * Login method.
   * @param userEmail
   * @param userPassword
   * @param type
   */
  login(userEmail: string, userPassword: string, type: string = 'CUSTOMER'): Observable<User|Customer> {
    const loginParams = {email: userEmail, password: userPassword, instanceId: this.instanceId, type};
    return this.http.post<User>('/api/identity/authenticate', loginParams).pipe(
      map((item: any) => {
        const tmpUser = this.userAdapter.adapt(item);
        this.currentUserSubject.next(tmpUser);
        this.saveUser();
        return tmpUser;
      })
    );
  }

  /**
   * Login method.
   * @param token
   * @param type
   */
  loginWithToken(token: string, type: string = 'CUSTOMER'): Observable<User|Customer> {
    const loginParams = {token, instanceId: this.instanceId, type};
    return this.http.post<User>('/api/identity/authenticate', loginParams).pipe(
      map((item: any) => {
        const tmpUser = this.userAdapter.adapt(item);
        this.currentUserSubject.next(tmpUser);
        this.saveUser();
        return tmpUser;
      })
    );
  }

  /**
   * Save current user in localstorage.
   */
  saveUser() {
    localStorage.setItem('systemeUUser', JSON.stringify(this.getCurrentUser()));
  }

  /**
   * Retrieve user from token.
   * @param token
   */
  getUserFromToken(token?: string): Observable<User> {
    let headers = new HttpHeaders();
    if (token !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<User>('/api/identity/me', { headers }).pipe(map((item: any) => this.userAdapter.adapt(item)));
  }

  /**
   * Request a reset password.
   * @param userEmail
   */
  requestResetPassword(userEmail: string) {
    let headers = new HttpHeaders();
    headers = headers.set('InstanceId', this.instanceId);

    return this.http.get('/api/customers/' + userEmail + '/resetPassword', { headers });
  }

  /**
   * Reset password.
   * @param userEmail
   * @param token
   * @param password
   */
  resetPassword(userEmail: string, token: string, password: string) {
    return this.http.post('/api/customers/' + userEmail + '/password/' + token, { password });
  }

  /**
   * Logout method.
   */
  logout(redirectToLogin: boolean = true): void {
    const wasUser: boolean = this.getCurrentUser() && this.getCurrentUser().isInstanceManager();
    this.currentUser = undefined;
    this.currentUserSubject.next(undefined);
    localStorage.removeItem('systemeUUser');
    if (redirectToLogin) {
      if (wasUser) {
        this.router.navigate(['/login-admin']);
      } else {
        this.router.navigate(['/login']);
      }
    }
  }
}
